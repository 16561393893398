import { Link } from "react-router-dom";
import CustomLink from "./CustomLink";

const CardParticulier = ({ img, alt, title, text, goTo, from }) => {
  return (
    <div className="h-[25rem] w-[12.25rem] shadow-md rounded-xl flex flex-col lg:w-[17rem] lg:h-[23rem] xx:w-[18rem] xx:h-[25rem]">
        <img className="w-full h-[8.1875rem] rounded-t-xl object-cover lg:h-[11rem] xx:h-[11rem]" src={img} alt={alt} />
        <div className="flex flex-col h-full p-2">
          <div className="w-fit h-fit mt-2 ">{title}</div>
          <div className="w-full mt-1 text-[0.8rem] text-[#8D8F90] lg:w-full text-left flex-grow">{text}</div>
          <CustomLink from={from} to={goTo} className="h-fit w-fit">
          <div className="flex items-center mt-auto cursor-pointer">
              <img src='./assets/svg/chevron-right-red.svg' alt="Chevron" />
              <p className="text-[0.75rem] text-[#8D8F90] ml-2">En savoir plus</p>
          </div>
          </CustomLink>
        </div>
    </div>
  );
};

export default CardParticulier;
