import React from "react";
import Retour from "../components/Retour";
import Footer from "../components/Footer";
export default function Tranquilite() {
  return (
    <>
      <div className="pt-[6rem] px-[1.5rem] font-poppins lg:pt-[8rem] xx:pl-[8rem] lg:pb-16 xx:w-4/5 xx:pt-[10rem] pb-16">
        <Retour page="/" section="valeurs" />
        <p className="uppercase font-bold text-[1.5rem] w-fit mt-6 lg:text-[2.25rem]">
          TRANQUILITÉ
        </p>
        <p className="text-left mt-6 ">
          L’application B Partner simplifie et accélère vos opérations.
        </p>
        <p className="text-left">
          En quelques clics, depuis votre smartphone ou ordinateur, vous pouvez
          effectuer des virements, consulter vos comptes, payer vos factures, et
          bien plus encore.
        </p>
        <br />
        <p className="text-left">
          B Partner vous aide à surveiller votre budget, suivre vos dépenses et
          planifier vos économies en toute simplicité.
        </p>
        <ul className="mt-6 text-left list-disc pl-[1.5rem] space-y-6">
          <li className="">
            <strong>Gain de temps :</strong> Les opérations financières
            deviennent plus rapides et faciles.
          </li>
          <li className="">
            <strong>Meilleure organisation :</strong> Une vue d’ensemble de
            votre situation financière vous permet de mieux organiser votre
            budget.
          </li>
          <li className="">
            <strong>Réduction du stress :</strong> Vous n'avez plus à vous
            soucier de vos finances au quotidien.
          </li>
          <li className="">
            <strong>Sécurité :</strong> B Partner est entièrement sécurisé, vous
            permettant de gérer votre argent en toute confiance.
          </li>
        </ul>
      </div>
      <Footer />
    </>
  );
}
