import React, { useState } from 'react';
import "react-toggle/style.css";
import Toggle from 'react-toggle';
import { useTpeContext } from '../../contexts/TpeContext';

export default function Step0() {
  const { state, updateState } = useTpeContext();
  const [errors, setErrors] = useState({});

  const validateFields = () => {
    const newErrors = {};

    const monthlyAmount = parseFloat(state.userInfo.monthly_amount);
    const transactionCount = parseFloat(state.userInfo.transaction_count);
    const costPerTransactionPercent = parseFloat(state.userInfo.cost_per_transaction_percent);
    const costPerTransactionCents = parseFloat(state.userInfo.cost_per_transaction_cents);

    if (isNaN(monthlyAmount) || monthlyAmount <= 0) {
      newErrors.monthly_amount = "Le volume mensuel doit être un nombre positif.";
    }

    if (isNaN(transactionCount) || transactionCount <= 0) {
      newErrors.transaction_count = "Le nombre de transactions doit être un nombre positif.";
    }

    if ((isNaN(costPerTransactionPercent) || costPerTransactionPercent <= 0) &&
        (isNaN(costPerTransactionCents) || costPerTransactionCents <= 0)) {
      newErrors.cost_per_transaction_cents = "Un coût par transaction ou un pourcentage doit être rempli avec une valeur positive.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleNext = () => {
    if (validateFields()) {
      updateState({ step: state.step + 1 });
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const normalizedValue = type === 'checkbox' ? checked : value.replace(/[,%€]/g, '').replace(',', '.');

    updateState({
      userInfo: {
        ...state.userInfo,
        [name]: normalizedValue,
      },
    });
  };

  return (
    <div className="flex flex-col p-4">
      <form className="grid grid-cols-1 lg:grid-cols-2 gap-4 space-y-4 lg:space-y-0">
        <label className="flex flex-col text-left font-semibold">
          <span>Volume de transaction mensuelles</span>
          <input
            type="text"
            name="monthly_amount"
            placeholder="ex: 10 000 €"
            value={state.userInfo.monthly_amount ?? ''}
            onChange={handleChange}
            className="border rounded px-4 py-1 mt-1 h-[3rem] text-[0.875rem] placeholder:text-[0.875rem] placeholder:font-light outline-none font-normal"
          />
          {errors.monthly_amount && <span className="text-red-500 text-[0.875rem] font-normal">{errors.monthly_amount}</span>}
        </label>

        <label className="flex flex-col text-left font-semibold">
          <span>Nombre de transactions mensuelles</span>
          <input
            type="text"
            name="transaction_count"
            placeholder="ex: 75"
            value={state.userInfo.transaction_count ?? ''}
            onChange={handleChange}
            className="border rounded px-4 py-1 mt-1 h-[3rem] text-[0.875rem] placeholder:text-[0.875rem] placeholder:font-light outline-none font-normal"
          />
          {errors.transaction_count && <span className="text-red-500 text-[0.875rem] font-normal">{errors.transaction_count}</span>}
        </label>

        <label className="flex flex-col text-left font-semibold">
          <span>Coût par transaction (%)</span>
          <input
            type="text"
            name="cost_per_transaction_percent"
            placeholder="ex: 1.75%"
            value={state.userInfo.cost_per_transaction_percent ?? ''}
            onChange={handleChange}
            className="border rounded px-4 py-1 mt-1 h-[3rem] text-[0.875rem] placeholder:text-[0.875rem] placeholder:font-light outline-none font-normal"
          />
          {errors.cost_per_transaction_percent && <span className="text-red-500 text-[0.875rem] font-normal">{errors.cost_per_transaction_percent}</span>}
        </label>

        <label className="flex flex-col text-left font-semibold">
          <span>Coût par transaction en centimes (€)</span>
          <input
            type="text"
            name="cost_per_transaction_cents"
            placeholder="ex: 0.02 euros (pour 2 cts)"
            value={state.userInfo.cost_per_transaction_cents ?? ''}
            onChange={handleChange}
            className="border rounded px-4 py-1 mt-1 h-[3rem] text-[0.875rem] placeholder:text-[0.875rem] placeholder:font-light outline-none font-normal"
          />
          {errors.cost_per_transaction_cents && <span className="text-red-500 text-[0.875rem] font-normal">{errors.cost_per_transaction_cents}</span>}
        </label>

        <label className="flex flex-col items-start justify-start space-x-2 text-left font-semibold lg:col-span-2">
          <span className='lg:mb-4'>Avez-vous besoin d'imprimer vos reçus ?</span>
          <Toggle
            defaultChecked={state.userInfo.has_printing}
            onChange={(e) => updateState({
              userInfo: {
                ...state.userInfo,
                has_printing: e.target.checked,
              },
            })}
            className="toggleInput"
          />
        </label>

        <button
          type="button"
          onClick={handleNext}
          className="bg-main-red text-white py-2 rounded h-[3rem] font-bold mt-4 lg:col-span-2 lg:w-[14rem] lg:ml-auto"
        >
          Suivant
        </button>
      </form>
    </div>
  );
}
