import React from 'react';
import { Link } from 'react-router-dom';
import { useAppContext } from '../contexts/AppContext';

export default function Retour({ page = '/', section = '' }) {
  const {state} = useAppContext();
  console.log(state)
  return (
    <Link className='w-fit h-fit' to={state.from}>
      <div className='flex flex-row'>
        <img alt='chevron left' src='./assets/svg/arrow-left.svg' />
        <p className='font-semibold font-poppins ml-1 cursor-pointer'>Retour</p>
      </div>
    </Link>
  );
}
