import React from 'react';
import Retour from '../components/Retour';
import Footer from '../components/Footer';

export default function Conseiller() {
  return (
    <>
      <div className='py-[6rem] px-[1.5rem] font-poppins lg:pt-[8rem] xx:pl-[8rem]'>
        <Retour page='/particuliers' section='valeurs' />
        <div className='lg:flex flex-row'>
          <div className='text-left lg:w-[30.25rem] xx:w-[42rem] xx:flex xx:flex-col xx:justify-center'>
            <p className='mt-4 uppercase font-bold text-[1.5rem] xx:text-[2rem] w-fit'>CONSEILLER FINANCIER</p>
            <h4 className='text-[1.125rem] font-semibold mt-4 xx:text-[1rem]'>Un conseiller en gestion patrimoniale à vos côté</h4>
            <p className='mt-8'>
            Chez B Partner, nous privilégions la personnalisation. Nos conseillers et experts financiers en gestion patrimoniale sont à votre disposition pour évaluer vos projets et vous accompagner en toute confiance et confidentialité.
              <br /><br />Ce service sans engagement permet à B Partner de proposer à nos clients une gamme complète de solutions financières et assurantielles.
            </p>
          </div>
          <img src='./assets/images/conseiller.png' alt='financial advisor' className='w-full h-[18.5rem] object-cover mt-6 lg:w-[40.25rem] lg:h-[22.81rem] lg:ml-auto xx:w-[50rem] xx:h-[25rem] xx:mr-[6rem]' />
        </div>

        {/* New Section for Additional Services */}
        <div className='w-full mt-12 text-left'>
          <p className='font-semibold'>Une gamme complète de services avec nos conseillers experts</p>
          <p className=' mt-4'>
          Nos conseillers en gestion patrimoniale sont à votre disposition pour vous accompagner sur les sujets suivants :
          </p>
          <ul className='list-disc list-inside space-y-4 mt-6'>
            <li><span className='font-semibold'>Conseils en fiscalité :</span> bilan, revue et optimisation</li>
            <li><span className='font-semibold'>Gestion de la dette :</span> restructuration et optimisation des outils et dettes</li>
            <li><span className='font-semibold'>Patrimoine professionnel :</span> optimisation, transmission, successions, placements, immobilier et SCI</li>
            <li><span className='font-semibold'>Investissements immobiliers :</span> investissement et acquisitions</li>
            <li><span className='font-semibold'>Assurance vie :</span> souscription, transfert, rédaction des clauses bénéficiaires</li>
            <li><span className='font-semibold'>Placements financiers :</span> analyse portefeuille, valorisation d’épargne financière via PEA, Comptes-titres, etc.</li>
            <li><span className='font-semibold'>Prévoyance :</span> bilan des couvertures, optimisation des garanties et contrats, professions libérales</li>
            <li><span className='font-semibold'>Transmission de patrimoine :</span> donation d’usufruit, SCI, vente en viager</li>
            <li><span className='font-semibold'>Retraite :</span> anticipation des droits, anticipation des besoins, capitalisation</li>
          </ul>
        </div>
      </div>
      <Footer />
    </>
  );
}
