import React from 'react'
import useIsMobile from '../hooks/useIsMobile'
import { Link } from 'react-router-dom'
import CustomLink from './CustomLink'

export default function CardOffer({img, alt, title='PRESTIGE', subtitle='un compte à la hauteur de votre personnalité' , bullets=["Accès au B Partner Club", 'Services Prestige', "Assistant personnel", "Service de e-reputation"], margin=false}) {
    const isMobile = useIsMobile()
    console.log(img, alt)
  return (
    <div className={`w-full h-[33rem] ${isMobile && "mt-[1.12rem]"} lg:w-[19.8125] xx:w-[25rem] xx:h-[42rem] relative ${!isMobile && margin ? 'lg:mr-[1.12rem] xx:mr-[2rem]' : ''}  `}>
        <img alt={alt} src={img} className='w-full h-full absolute z-0'/>
        <div className='w-full h-full bg-[#8888887a] z-10 absolute flex flex-col text-white font-poppins items-start text-left'>
            <div className='p-[2.4rem] xx:p-[2.8rem]'>
                <p className='text-[2rem] font-semibold leading-none xx:text-[2.5rem]'>{title}</p>
                <p className=' font-light xx:text-[1.125rem]'>{subtitle}</p>
                <ul className='flex flex-col space-y-[1.25rem] mt-[2rem] xx:text-[1.125rem]'>
                    {bullets.map((bullet, index) => (
                        <li key={index} className=''>
                            {bullet}
                        </li>
                    ))}
                </ul>
            </div>
            <CustomLink from='/#sectionOffers' to='/choixOffre' className='w-fit h-fit mx-auto mt-auto mb-[2.4rem] cursor-pointer'>
            <button className='h-[3rem] w-[16.8572rem] lg:w-[15.625rem] xx:w-[18rem] xx:text-[1.125rem] bg-main-red text-white font-bold rounded-md '>Découvrir</button>
            </CustomLink>
        </div>
    </div>
  )
}
