import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import useIsMobile from "../hooks/useIsMobile";
import SimulateTpeCost from "./SimulateTpeCost";

export default function Header() {
  const pages = [
    { url: "/", tab: "Accueil" },
    { url: "/particuliers", tab: "Particulier" },
    { url: "/professionnels", tab: "Professionnel" },
    { url: "/bPartnerFidelite", tab: "B Partner Fidélité" },
    { url: "/bPartnerClub", tab: "B Partner Club" },
  ];

  const isMobile = useIsMobile();
  const location = useLocation();
  const [isFullScreen, setIsFullScreen] = useState(false);

  // Fonction pour basculer en mode plein écran
  const toggleFullScreen = () => setIsFullScreen(!isFullScreen);

  if (isFullScreen) {
    // Rendu en plein écran si isFullScreen est true
    return (
      <div className="fixed inset-0 w-full h-full text-white flex flex-col z-20 bg-black">
        <div className="w-full h-[5.18rem] flex items-center justify-between px-[1rem]">
          <Link to="/" onClick={toggleFullScreen}>
            <img
              alt="logo b partner"
              src="./assets/images/logo-b-partner.png"
              className="w-[5.8125rem] lg:w-[7.6875rem] h-[3.1875rem] lg:h-[5rem]"
            />
          </Link>
          <img
            alt="close button"
            src="./assets/svg/close-white.svg"
            onClick={toggleFullScreen}
            className=""
          />
        </div>
        <div className="px-[1.5rem] flex flex-col h-full justify-between">
          {/*  <div id='search' className='border-b w-full h-[3.75rem] flex gap-4 items-center px-2 mt-[1.5rem]'>
            <input type='text' placeholder='Rechercher' className='w-[16.3rem] bg-transparent text-[1.125rem] focus:outline-none text-grey-text font-poppins'/>
            <img
              alt="search icon"
              src="./assets/svg/search.svg"
              className="w-[2.5rem] h-[2.5rem] p-[0.625rem] rounded-full cursor-pointer border"
              onClick={toggleFullScreen}
            />
          </div> */}

          <ul
            id="menu"
            className="flex flex-col justify-between gap-[1rem] w-[10.625rem] text-left -translate-y-12 my-auto"
          >
            {pages.map((page) => (
              <Link
                key={page.url}
                to={page.url}
                className={`text-[1rem] cursor-pointer ${
                  location.pathname === page.url
                    ? "text-white"
                    : "text-grey-text"
                }`}
                onClick={toggleFullScreen} // Ferme le menu en cliquant sur un lien
              >
                {page.tab}
              </Link>
            ))}
          </ul>

          <div className="flex flex-row items-center gap-[1rem] mb-8 justify-center">
            <a className="cursor-pointer" href="#" onClick={toggleFullScreen}>
              Se connecter
            </a>
            <button className="h-[3rem] w-[11.875rem] bg-main-red text-white font-dmSans font-bold rounded-l">
              Ouvrir un compte
            </button>
          </div>
        </div>
      </div>
    );
  }

  let minusWidth = "0px";
  switch (location.pathname) {
    case "/":
    case "/particuliers":
    case "/professionnels":
    case "/bPartnerFidelite":
    case "/bPartnerClub":
      minusWidth = "8px";
      break;
  }

  return (
    <>
      <div
        className="bg-black h-[5.1875rem] lg:h-[5.5rem] fixed top-0 left-0 text-white flex items-center z-20 lg:py-[0.75rem] lg:px-[4rem] p-[1rem] justify-between"
        style={{ width: !isMobile ? `calc(100% - ${minusWidth}` : "100%" }}
      >
        <Link to="/">
          <img
            alt="logo b partner"
            src="./assets/images/logo-b-partner.png"
            className="w-[4.8125rem] lg:w-[7.6875rem] h-[3.1875rem] lg:h-[4.25rem]"
          />
        </Link>
        {!isMobile ? (
          <>
            <ul
              id="menu"
              className="h-[1.5rem] flex flex-row justify-between gap-[1rem] xx:w-[38rem] xx:mx-auto"
            >
              {pages.map((page) => (
                <Link
                  key={page.url}
                  to={page.url}
                  className={`text-[0.875rem] xx:text-[1rem] cursor-pointer ${
                    location.pathname === page.url
                      ? "text-white"
                      : "text-grey-text"
                  }`}
                >
                  {page.tab}
                </Link>
              ))}
            </ul>
            <div className="flex flex-row items-center gap-[1rem] rounded-md">
              <a
                className="cursor-pointer"
                href="https://app.b-partner.com/connexion"
                target="_blank"
              >
                Se connecter
              </a>
              <Link to="https://app.b-partner.com/register" target="_blank">
                <button className="h-[3rem] w-[11.875rem] bg-main-red text-white font-dmSans font-bold rounded">
                  Ouvrir un compte
                </button>
              </Link>
            </div>
          </>
        ) : (
          <div className="flex gap-4">
            <img
              alt="profile icon"
              src="./assets/svg/profil.svg"
              className="w-[2.5rem] cursor-pointer"
              onClick={toggleFullScreen}
            />
            <img
              alt="burger menu icon"
              src="./assets/svg/burger.svg"
              className="w-[2.25rem] cursor-pointer"
              onClick={toggleFullScreen}
            />
          </div>
        )}
      </div>
      {location.pathname === "/tpe" && <SimulateTpeCost />}
    </>
  );
}
