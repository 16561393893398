import React from 'react'
import useIsMobile from '../../hooks/useIsMobile'
import { useTpeContext } from '../../contexts/TpeContext'

export default function () {
    const {state, updateState} = useTpeContext()
    const isMobile= useIsMobile()
    const retour = () => {
        updateState({ step: state.step - 1 });
    }
  return (
    <div onClick={retour} className='flex flex-row absolute cursor-pointer top-[0.2rem] left-3 lg:top-2'>
        <img alt='chevron left' src='./assets/svg/arrow-left.svg' />
        {!isMobile && <p className='font-semibold font-poppins ml-1 cursor-pointer'>Retour</p>}
        
    </div>
  )
}
