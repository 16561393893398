import React, { createContext, useContext, useState } from 'react';

const TpeContext = createContext();

export const useTpeContext = () => useContext(TpeContext);

export const TpeProvider = ({ children }) => {
  const [state, setState] = useState({
    step: 0,
    userInfo: {
      monthly_amount: null,                // Anciennement monthlyVolume
      transaction_count: null,             // Anciennement monthlyTransactions
      has_printing: true,                  // Anciennement print
      terminal_cost: null,                 // Anciennement terminalCost
      rental_cost: null,                   // Anciennement rentingTerminalCost
      maintenance_cost: null,              // Anciennement maintenanceCost
      cost_per_transaction_percent: null,  // Anciennement percentageTransactionCost
      cost_per_transaction_cents: null,    // Anciennement transactionCost
    },
  });

  const updateState = (newState) => {
    setState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  };

  return (
    <TpeContext.Provider value={{ state, updateState }}>
      {children}
    </TpeContext.Provider>
  );
};
