import React from 'react'
import { useTpeContext } from '../../contexts/TpeContext'

export default function ProgressBar() {
    const {state, updateState} = useTpeContext()
  return (
    <div className='w-full flex flex-row justify-between'>
        <div className={`w-[32.5%] h-[0.6875rem] rounded-lg red-gradient `}></div>
        <div className={`w-[32.5%] h-[0.6875rem] rounded-lg ${state.step > 0 ? 'red-gradient' : 'bg-[#E2E2E2]'}`}></div>
        <div className={`w-[32.5%] h-[0.6875rem] rounded-lg ${state.step > 1 ? 'red-gradient' : 'bg-[#E2E2E2]'}`}></div>
    </div>
  )
}
