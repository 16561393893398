import React, { useState, useEffect } from "react";
import useIsMobile from "../hooks/useIsMobile";
import { Link } from "react-router-dom";

export default function SectionParticulier({
  title = (
    <>
      notre offre <span className="text-main-red">prestige</span>
    </>
  ),
  lign = "des services qui vous mènent vers l'excellence",
  subTitle = "DÉPENSEZ TOUT EN ÉTANT RÉCOMPENSÉ",
  text = "Le B Partner Club vous permet d’obtenir de nombreux privilèges comme des réductions chez nos partenaires ou des avantages B Partner exclusifs.",
  imgName = "section1-home.png",
  altImg = "woman checking her account",
  align = "left",
  id = "section",
  textBtn = "Souscrire",
  goTo = "#",
  blank = false,
}) {
  const isMobile = useIsMobile();
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }, []);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  // Diviser le texte en deux parties
  const firstPart = text.slice(0, Math.floor(text.length / 2));
  const secondPart = text.slice(Math.floor(text.length / 2));

  return (
    <div
      className={`${
        isMobile ? null : "container-snap"
      } font-poppins flex flex-col justify-center lg:mt-[5.5rem] my-[2.5rem] `}
      id={id}
    >
      <div>
        <p className="mt-8 uppercase font-semibold text-[1.5rem] lg:mt-16 lg:text-[2rem] xx:mt-0 xx:pt-[7rem] xx:text-[2.5rem]">
          {title}
        </p>
        <p className="uppercase text-main-red tracking-wider px-4 font-semibold mt-2 lg:tracking-widest xx:text-[1.25rem]">
          {lign}
        </p>
      </div>
      <div
        className={`flex flex-col justify-start px-[1.5rem] lg:my-0 font-poppins lg:flex-row lg:items-center lg:px-0 lg:mt-8 xx:mt-0 xx:justify-center ${
          align === "left" ? "lg:pr-[1.5rem]" : "lg:pl-[1.5rem]"
        }`}
      >
        <div
          className={`${
            align === "left" ? "lg:order-2" : "lg:order-1"
          } lg:w-[34.25rem] lg:flex lg:flex-col lg:h-[29.375rem] ${
            align === "left" ? "lg:ml-[3rem]" : "lg:mr-[3rem]"
          } xx:h-[42rem] xx:justify-center flex flex-col justify-start`}
        >
          <p className="uppercase w-full text-left font-bold text-[1.5rem] mt-[1.25rem] lg:text-[2rem] xx:text-[2.5rem]">
            {subTitle}
          </p>
          <p className="text-justify text-grey-text">
            {isMobile ? (
              <>
                {firstPart}
                {!isExpanded && "... "}
                {isExpanded && secondPart}
                <span
                  onClick={handleToggle}
                  className="text-main-red font-bold cursor-pointer"
                >
                  {isExpanded ? " Voir moins" : " Voir plus"}
                </span>
              </>
            ) : (
              text
            )}
          </p>
          <Link to={goTo} target={blank && "_blank"} className="w-fit h-fit">
            <button className="mt-[2.5rem] h-[3rem] w-[8rem] border-2 border-main-red text-main-red font-bold rounded">
              {textBtn}
            </button>
          </Link>
        </div>
        <div className={`${align === "left" ? "lg:order-1" : "lg:order-2"}`}>
          <img
            alt={altImg}
            src={
              isMobile
                ? "./assets/images/" + imgName
                : "./assets/images/desktop-" + imgName
            }
            className="w-full h-auto mt-[3rem] mb-[1.5rem] lg:w-[41.25rem] lg:h-[29.375rem] object-cover lg:mt-0 lg:mb-0 xx:w-[60rem] xx:h-[34rem]"
          />
        </div>
      </div>
    </div>
  );
}
