import React from 'react'
import Retour from '../components/Retour'
import Footer from '../components/Footer'
export default function Exclusivite() {
  return (
    <>
    <div className='py-[6rem] px-[1.5rem] font-poppins lg:pt-[8rem] xx:pl-[8rem] lg:pb-16 xx:w-4/5 xx:pt-[10rem]' >
      <Retour page='/' section ='valeurs'/>
      <p className='uppercase font-bold text-[1.5rem] w-fit mt-6 lg:text-[2.25rem]'>EXCLUSIVITÉ</p>
      <p className='text-left mt-6'>Chez B Partner nous vous proposons des services uniques et exclusifs :</p>
      <ul className='mt-6 text-left list-disc pl-[1.5rem] space-y-6'>
        <li className=''>
          <strong>Expérience utilisateur innovante :</strong>  Chaque interaction avec notre application est personnalisée selon vos préférences, que ce soit pour l’interface ou les notifications.
        </li>
        <li className=''>
          <strong>Récompenses personnalisées:</strong>  Grâce aux points de fidélité de B Partner, cumulez des avantages et bénéficiez de réductions. 
        </li>
        <li className=''>
          <strong>Accès privilégié :</strong>  Profitez d’un accès privilégié à des conseillers dédiés, à des produits exclusifs ou à des événements spéciaux.
        </li>
        <li className=''>
          <strong>Services de conciergerie :</strong> Nous proposons des services de conciergerie pour gérer vos démarches administratives liées aux finances (impôts, assurances, etc.) ainsi que pour louer une voiture, réserver un restaurant, etc.

        </li>
        <li className=''>
          <strong>Partenariats exclusifs :</strong>  Bénéficiez de réductions et d’avantages exclusifs grâce à nos partenariats avec des marques de luxe, des restaurants et des hôtels.<br />N’hésitez pas à nous contacter pour plus d’informations !

        </li>
      </ul>
    </div>
      <Footer />
    </>
  )
}
