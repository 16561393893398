import { Link } from "react-router-dom";

const CardBPC = ({
  img,
  alt,
  title,
  text,
  goTo,
  subText = [
    [
      "handshake",
      "Restaurants partenaires : Girafe, Le Piaf, Maxim's, MUN, Louie, Maison Revka, et bien d'autres restaurants d’exception du groupe Paris Society.",
    ],
    [
      "ticket",
      "Accès exclusifs : Matchs de la Ligue des Champions, Rencontres à Roland Garros, Paddock du Grand Prix de Spa-Francorchamps, Ryder Cup, et plus encore.",
    ],
  ],
}) => {
  return (
    <div className="h-[23rem] w-[13.75rem] rounded-xl lg:rounded-none flex flex-col lg:w-[22rem] lg:h-[25rem] xx:w-[25rem] xx:h-[27rem] shadow-md">
      <img
        className="w-full h-[8.1875rem] rounded-t-xl lg:rounded-none object-cover lg:h-[12rem] xx:h-[15rem] grayscale"
        src={img}
        alt={alt}
      />
      <div className="flex flex-col h-full p-2">
        <div className="w-fit h-fit mt-2 text-left">{title}</div>
        <div className="w-full mt-1 text-[0.8rem] lg:w-full text-justify flex-grow lg:text-[1rem] xx:text-[1rem]">
          {text}
        </div>
        {/* <div className="flex items-center mt-auto space-y-2 flex-col">
          {subText.map((tableau, index) => (
            <div key={index} className="flex items-start space-x-2 mt-2">
              <img src={`./assets/svg/${tableau[0]}.svg`} alt={tableau[0]} className="w-4 h-4 mt-1" />
              <p className="text-[0.75rem] text-left text-grey-text">{tableau[1]}</p>
            </div>
          ))}
        </div> */}
      </div>
    </div>
  );
};

export default CardBPC;
