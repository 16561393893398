import React from 'react'
import Retour from '../components/Retour';
import Footer from '../components/Footer';

export default function Ereputation() {

  return (
    <>
    <div className='pt-[6rem] px-[1.5rem] font-poppins lg:pt-[8rem] xx:pl-[8rem] lg:pb-[5rem] pb-8'>
      <Retour page='/' section ='sectionReputation'/>
      <div className='lg:flex flex-row lg:h-fit lg:mt-[3rem] xx:mt-[3rem] '>
        <div className='text-left lg:w-[30.25rem] xx:w-[42rem] xx:flex xx:flex-col xx:justify-center'>
          <p className='mt-4 uppercase font-bold text-[1.5rem] xx:text-[2rem] w-fit'>SERVICE E-REPUTATION</p>
          <p className='mt-4'>
          Ce service, destiné aux clients B Partner, propose à ceux qui le souhaitent une évaluation de leur réputation en ligne (internet et réseaux sociaux).</p>
          <p className='mt-4'>
          Il est offert en partenariat avec Social Guard, une société spécialisée dans le marketing et la communication numérique.</p>
          <p className='mt-4'>
          L’évaluation de l’e-réputation est offerte une fois lors de l’entrée en relation et fait partie intégrante de l’offre Prestige. Après cette évaluation, les clients B Partner peuvent demander une analyse plus détaillée de leur réputation en ligne, sous réserve de l’acceptation d’un devis spécifique.</p>
        </div>
          <img src='./assets/images/desktop-section5-home.png' alt='old man checking his b partner account' className='w-full h-[18.5rem] object-cover mt-6 lg:w-[40.25rem] lg:h-[22.81rem] lg:ml-auto xx:w-[50rem] xx:h-[25rem] xx:mr-[6rem]'/>
      </div>
    </div>
    <Footer />
  </>
  )
}
