import React,{useEffect} from 'react';
import useIsMobile from '../hooks/useIsMobile';
import { Link } from 'react-router-dom';
import CustomLink from './CustomLink';

export default function Section({
  title = "B partner club",
  subTitle = "DÉPENSEZ TOUT EN ÉTANT RÉCOMPENSÉ",
  text = 'Le B Partner Club vous permet d’obtenir de nombreux privilèges comme des réductions chez nos partenaires ou des avantages B Partner exclusifs.',
  bulletPoints = [
    <p><span className='font-bold'>Recevez</span> de l’argent dès l’ouverture de compte</p>, 
    <p>Profitez de <span className='font-bold'>réductions</span> chez nos partenaires</p>, 
    <p>Cumulez du <span className='font-bold'>cashback</span> en utilisant votre carte</p>
  ],
  imgName ='section1-home.png',
  altImg= 'woman checking her account',
  align='left',
  id='section',
  goTo='#',
  button=true,
  from='/'
}) {
    const isMobile = useIsMobile()
    useEffect(() => {
        const hash = window.location.hash;
        if (hash) {
            const element = document.querySelector(hash);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }
    }, []);
  return (
    <div className={`${isMobile? null : "container-snap"} flex justify-center`} id={id}>
        <div className={`flex flex-col justify-start px-[1.5rem] my-[2.5rem] lg:my-0 font-poppins lg:flex-row lg:items-center lg:px-0 ${align ==='left' ? 'lg:pr-[1.5rem]' : "lg:pl-[1.5rem]"} lg:mt-[5.5rem]`}>
            <div className={` ${align==='left' ? "lg:order-2" : "lg:order-1"} lg:w-[34.25rem] lg:flex lg:flex-col lg:h-[29.375rem] ${align==='left' ? 'lg:ml-[3rem]' : 'lg:mr-[3rem]'} xx:h-[42rem] xx:justify-center flex flex-col justify-start`}>
                <p className='uppercase text-main-red font-semibold w-full text-left text-[0.95rem] tracking-[0.2rem]'>{title}</p>
                <p className='uppercase w-full text-left font-bold text-[1.5rem] mt-[1.25rem] lg:text-[2rem] xx:text-[2.5rem]'>{subTitle}</p>
                <p className='text-left text-grey-text'>
                    {text}
                </p>
                <ul className='text-left mt-[1.5rem] space-y-[0.25rem]'>
                    {bulletPoints.map((point, index) => (
                    <li key={index} className='text-grey-text flex flex-row gap-[1rem]'>
                        •{point}
                    </li>
                    ))}
                </ul>
                <CustomLink from={from} to={goTo} className={`w-fit h-fit ${!button && 'hidden'}`}>
                <button className="mt-[2.5rem] h-[3rem] w-[8rem] border-2 border-main-red text-main-red font-bold rounded">
                    Découvrir
                </button>
                </CustomLink>
            </div>
            <div className={`${align==='left' ? "lg:order-1" : "lg:order-2"}`}>
                <img alt={altImg} src={isMobile ? "./assets/images/" + imgName : './assets/images/desktop-' + imgName} className={`w-full h-auto mt-[3rem] mb-[1.5rem] lg:w-[41.25rem] lg:h-[29.375rem] object-cover lg:mt-0 lg:mb-0 xx:w-[60rem] xx:h-[42rem] `}/>
            </div>
        </div>
    </div>
  );
}
