import React, { createContext, useContext, useState } from 'react';

// Créer le contexte
const AppContext = createContext();

// Créer un fournisseur de contexte
export const AppProvider = ({ children }) => {
  // Créer un objet d'état modifiable
  const [state, setState] = useState({
    modalOpen:false,
    modalData:{},
    modalElement:<></>,
    from:'/'
  });

  // Fonction pour mettre à jour une propriété spécifique de l'objet
  const updateContext = (key, value) => {
    setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  return (
    <AppContext.Provider value={{ state, updateContext }}>
      {children}
    </AppContext.Provider>
  );
};

// Hook personnalisé pour utiliser le contexte
export const useAppContext = () => useContext(AppContext);
