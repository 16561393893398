import React from "react";
import { useTpeContext } from "../../contexts/TpeContext";
import useIsMobile from "../../hooks/useIsMobile";
import ProgressBar from "./ProgressBar";
import Step0 from "./Step0";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Final from "./Final";
import RetourTpe from "./RetourTpe";

export default function DynamicSection() {
  const renderStep = (step) => {
    switch (step) {
      case 0:
        return <Step0 />;
      case 1:
        return <Step1 />;
      case 2:
        return <Step2 />;
      case 3:
        return <Final />;
      default:
        return <p>Error</p>;
    }
  };
  const isMobile = useIsMobile();
  const { state, updateState } = useTpeContext();
  return (
    <div id="simulateur" className="lg:pt-[9rem]">
      <div className="flex flex-col my-8 lg:w-[56.25rem] xx:w-[60rem] lg:mx-auto lg:p-[2rem] lg:rounded-lg lg:shadow-[0px_0px_24px_0px_#3C3C3C] ">
        <div className="flex flex-row items-center relative">
          {state.step > 0 && <RetourTpe />}
          {state.step === 3 || state.step === 2 ? (
            <p className="font-bold lg:text-[1.5rem] mx-auto">
              Resultat de votre simulation
            </p>
          ) : (
            <p className="font-bold lg:text-[1.5rem] mx-auto">
              Simulez le coût de votre TPE
            </p>
          )}
        </div>
        <div className="my-4">{state.step < 3 && <ProgressBar />}</div>
        <div id="content">{renderStep(state.step)}</div>
      </div>
    </div>
  );
}
