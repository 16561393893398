import React from "react";
import { Link } from "react-router-dom";
import { useAppContext } from "../contexts/AppContext";
import Faq from "./Faq";
import ContactForm from "./ContactForm";
export default function Footer() {
  const { state, updateContext } = useAppContext();
  const openFaq = () => {
    updateContext("modalOpen", true);
    updateContext("modalElement", <Faq />);
  };
  const openForm = () => {
    updateContext("modalOpen", true);
    updateContext("modalElement", <ContactForm />);
  };
  return (
    <div className="h-fit w-full bg-black footer text-white px-[1.5rem] xx:px-[4rem]">
      <div className="border-b-2 py-8">
        <div className="lg:flex lg:flex-row justify-between items-center">
          <Link to="https://app.b-partner.com/register" target="_blank">
            <p className="text-left text-[1.25rem] py-4">
              Rejoignez B <span className="text-main-red">Partner</span>
            </p>
          </Link>
          <div className="flex flex-row justify-between cursor-pointer">
            <p className="w-fit">Suivez-nous sur les réseaux sociaux</p>
            <Link
              to="https://www.linkedin.com/company/bpartnerbanking"
              target="_blank"
            >
              <img
                alt="linkedin logo"
                src="./assets/svg/linkedin.svg"
                className="w-[1.5rem] h-[1.5rem] lg:ml-4"
              />
            </Link>
          </div>
        </div>
        <div className="text-justify text-[0.825rem] font-dmSans lg:flex lg:flex-row lg:mt-[1.5rem] lg:justify-between">
          <div className="space-y-4 mt-4 lg:w-[40rem] lg:mt-0 lg:space-y-0">
            <p>
              Le compte B Partner Premium, Premium Business, Prestige ou
              Prestige Business, est un compte portefeuille de monnaie
              électronique aussi appelé "e-money wallet"
            </p>
            <br />
            <p>
              La carte de paiement est émise par Paynovate SA en vertu d’une
              licence de Visa International Inc. Paynovate SA est autorisée par
              la Banque National de Belgique à émettre de la monnaie
              électronique et à fournir les services de paiement associés (réf
              0506.763.929), dans le cadre de la réglementation applicable aux
              établissements de Monnaie Électronique. Paynovate SA est un membre
              principal de Visa.
            </p>
          </div>
          <div className="space-y-4 mt-8 lg:w-[23rem] lg:mt-0 lg:space-y-0">
            <p className="">
              B Partner SRL est une société immatriculée au registre de commerce
              en Belgique, sous le numéro BE 1003965341.{" "}
            </p>
            <p>Siège social : 161 Drève Richelle, 1410 Waterloo, Belgique</p>
            <p>Téléphone +33 1 82 83 06 80,</p>
            <p>Site web : www.b-partner.com</p>
          </div>
        </div>
      </div>
      <div className="py-8 flex flex-col space-y-8 lg:space-y-0 lg:flex lg:flex-row lg:justify-between lg:items-center">
        <img
          alt="logo b partner"
          src="./assets/images/logo-b-partner.png"
          className="w-[4rem] h-[2.43rem]"
        />
        <p className="font-poppins text-[0.825rem] text-left">
          © B Partner 2024 - ISO 14001, ISO 27001, ISO 9001
        </p>
        <ul className="flex flex-row flex-wrap text-[0.825rem] text-left lg:space-x-6">
          <li className="w-1/3 lg:w-fit">
            <Link to="/">Accueil</Link>
          </li>
          <li className="w-1/3 lg:w-fit">
            <a href="./assets/docs/cgu.pdf" download={"cgu.pdf"}>
              CGU
            </a>
          </li>
          <li className="w-1/3 lg:w-fit">
            <a
              href="./assets/docs/mLegaleBpartner.pdf"
              download={"mLegaleBpartner.pdf"}
            >
              Mentions légales
            </a>
          </li>
          <li className="w-1/3 lg:w-fit">
            <a
              href="./assets/docs/confidentialite.pdf"
              download={"confidentialite.pdf"}
            >
              Confidentialité
            </a>
          </li>
          <li onClick={openFaq} className="w-1/3 lg:w-fit cursor-pointer">
            FAQ
          </li>
          <li onClick={openForm} className="w-1/3 lg:w-fit cursor-pointer">
            Contact
          </li>
        </ul>
      </div>
    </div>
  );
}
