import { Link } from "react-router-dom";
import CustomLink from "./CustomLink";

const CardFidelity = ({ img, alt, title, text, goTo, from }) => {
  return (
    <div className="h-[25rem] w-[14rem] rounded-xl flex flex-col lg:w-[17rem] lg:h-[23rem] xx:w-[18rem] xx:h-[25rem]">
        <img className="w-full h-[10rem] max-h-[10rem] rounded-t-xl object-cover lg:h-[17rem] xx:h-[25rem]" src={img} alt={alt} />
        <div className="flex flex-col h-[12rem] p-2">
          <div className="w-fit h-fit mt-2 ">{title}</div>
          <div className="w-full mt-1 text-[0.8rem] text-[#8D8F90] lg:w-full text-left flex-grow">{text}</div>
          <CustomLink from={from} to={goTo} className="h-fit w-fit">
          <div className="flex items-center mt-auto cursor-pointer">
              <img src='./assets/svg/chevron-right-red.svg' alt="Chevron" />
              <p className="text-[0.75rem] text-[#8D8F90] ml-2">Découvrir</p>
          </div>
          </CustomLink>
        </div>
    </div>
  );
};

export default CardFidelity;
