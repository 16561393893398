import React from 'react'
import CustomLink from './CustomLink'
export default function CardFormule({data, from='/professionnels#comparatifPro'}) {
  return (
    <div className='flex flex-col items-center lg:w-[40%] lg:h-fit'>
        <p className={`hidden lg:block uppercase w-full text-left font-semibold text-[1.4rem]`}>{data.title}</p>
        <div className='lg:flex lg:flex-row xx:w-[27rem]'>
            <img alt='card' src={data.img} className='overflow-visible lg:w-[9.6rem] lg:h-[6.9rem] xx:w-[20rem] xx:h-[15rem]'/>
            <div className='flex flex-col items-center -translate-y-16 lg:-translate-y-0'>
                <p className='w-1/2 lg:w-full'><span className='text-[2.25rem]'>{data.yearPrice}€</span>/mois sur base annuelle</p>
            </div>
        </div>
        <CustomLink target='_blank' to='https://app.b-partner.com/register' from={from} className={"w-full"}>
        <button className='w-full rounded text-white font-bold bg-main-red h-[3rem] -translate-y-14 lg:-translate-y-0 lg:mt-[0.38rem]'>Ouvrir un compte</button>
        </CustomLink>
    </div>
  )
}
