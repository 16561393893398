import React from "react";
import Retour from "../components/Retour";
import Footer from "../components/Footer";
import DynamicSection from "../components/TPE/DynamicSection";
export default function Tpe() {
  return (
    <>
      <div className="pt-[10rem] px-[1.5rem] lg:pt-[13.5rem] xx:px-[6rem] font-poppins mb-[5rem]">
        <Retour page="/" section="tpe" />
        <div className="flex flex-col lg:flex-row lg:justify-around">
          <div className="lg:w-[30.25rem] xx:w-[35rem]">
            <p className="text-[1.5rem] font-bold uppercase text-left mt-4 lg:text-[2.25rem]">
              terminaux de paiement mobile
            </p>
            <p className="font-semibold text-[1.125rem] text-left mt-2">
              Acceptez des paiements sur votre téléphone c’est possible!
            </p>
            <p className="text-left">
              Vous pouvez désormais accepter des paiements sur votre téléphone
              Android grâce à votre compte B Partner.
            </p>
            <p className="text-left mt-4">
              Notre application intuitive, connectée à votre compte, transforme
              votre téléphone en un véritable terminal de paiement. Vous pouvez
              ainsi accepter les paiements par carte de vos clients en toute
              simplicité et sécurité, tout en suivant chaque transaction.
            </p>
          </div>
          <img
            alt="smartphone bpartner"
            src="./assets/images/smartphone-bpartner.png"
            className="mt-4 lg:w-[40.25rem] lg:h-[22.81rem] object-cover xx:w-[50.25rem] xx:h-[28rem] lg:mt-0"
          />
        </div>

        <div className="flex flex-col lg:mt-[4.81rem]">
          <div className="lg:w-fit lg:mx-auto">
            <p className="uppercase text-main-red text-left font-semibold tracking-widest leading-relaxed mt-4 lg:w-fit lg:mx-auto">
              COÛTS SOLUTION DE POINT DE VENTE MOBILE
            </p>
            <p className="uppercase font-bold text-left text-[1.5rem] mt-2 lg:text-[2.6rem]">
              DES COÛTS ULTRA-COMPETITIFS
            </p>
          </div>
          <div className="flex flex-col space-y-6 lg:space-y-0 lg:flex-row lg:justify-around lg:mt-8 xx:w-[75%] xx:mx-auto">
            <div className="flex flex-col justify-center items-center w-full h-[14rem] mt-8 lg:mt-0 shadow-custom-red lg:w-[16.81rem] lg:h-[14.18rem] lg:px-4">
              <p className="text-main-red text-[3rem] font-semibold">0€</p>
              <p className="text-[1.125rem] font-semibold mt-4">
                Coût d'installation
              </p>
              <p className="text-grey-text text-[0.875rem]">
                Aucun coût pour l’installation de votre terminal de paiement
              </p>
            </div>
            <div className="flex flex-col justify-center items-center w-full h-[14rem] shadow-custom-red lg:w-[16.81rem] lg:h-[14.18rem] lg:px-4">
              <p className="text-main-red text-[3rem] font-semibold">0€</p>
              <p className="text-[1.125rem] font-semibold mt-4">
                Coût de maintenance
              </p>
              <p className="text-grey-text text-[0.875rem]">
                Tout est sur votre mobile, aucun coût supplémentaire
              </p>
            </div>
            <div className="flex flex-col justify-center items-center w-full h-[14rem] shadow-custom-red lg:w-[16.81rem] lg:h-[14.18rem] lg:px-4">
              <p className="text-main-red text-[3rem] font-semibold">0,7%</p>
              <p className="text-[1.125rem] font-semibold mt-4">
                Coût de transaction
              </p>
              <p className="text-grey-text text-[0.875rem]">
                Un coût de transaction bas défiant toute concurrence
              </p>
            </div>
          </div>
        </div>

        <div className="flex flex-col mt-8 lg:flex-row lg:mt-[6rem]">
          <div className="lg:w-[27rem] xx:w-[40rem]">
            <p className="uppercase text-main-red text-left font-semibold tracking-widest leading-relaxed mt-4">
              APPAREILS DE PAIEMENT CONNECTéS
            </p>
            <p className="uppercase font-bold text-left text-[1.5rem] mt-2 lg:text-[2.6rem] lg:mt-8">
              LOUEZ NOS TERMINAUX DE PAIEMENT ROBUSTES
            </p>
            <p className="text-left font-semibold">
              Louez nos terminaux de paiement robustes avec imprimante et carte
              SIM pour faciliter vos ventes
            </p>
            <p className="text-left text-grey-text">
              Notre gamme de terminaux B Partner vous accompagne partout afin
              d’accélérer vos ventes. Notre modèle de location « all-in »,{" "}
              <span className="text-black font-semibold">
                sans engagement, couplé à un coût de transaction très faible
              </span>{" "}
              vous permet de rester compétitif et de protéger vos marges !
            </p>
          </div>

          <div className="mt-4 flex flex-col space-y-8 lg:flex-row lg:space-y-0 lg:w-[42rem] lg:justify-between lg:ml-auto xx:w-[50rem]">
            <div className="w-full h-[33rem] relative lg:w-[19.8rem] xx:w-[22.5rem] xx:h-[36rem]">
              <img
                alt="tpe"
                src="./assets/images/tpe1.png"
                className="w-full h-full"
              />
              <div className="absolute w-full h-full top-0 bg-[#58585880] flex flex-col justify-end py-4 px-4">
                <p className="text-white text-[1.75rem] font-bold text-left">
                  B Partner Pro
                </p>
                <p className="text-left text-white">
                  B Partner Pro pour un usage avec imprimante, une large
                  autonomie et une carte SIM fournit avec l’appareil.
                </p>
              </div>
            </div>
            <div className="w-full h-[33rem] relative lg:w-[19.8rem] xx:w-[22.5rem] xx:h-[36rem]">
              <img
                alt="tpe"
                src="./assets/images/tpe2.png"
                className="w-full h-full object-cover"
              />
              <div className="absolute w-full h-full top-0 bg-[#58585880] flex flex-col justify-end py-4 px-4">
                <p className="text-white text-[1.75rem] font-bold text-left">
                  B Partner Flex
                </p>
                <p className="text-left text-white">
                  B Partner Flex pour notre terminal compact, simple et tout
                  terrain et sa carte SIM fournit avec l’appareil.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col lg:mt-[4.81rem]">
          <div className="lg:w-fit lg:mx-auto">
            <p className="uppercase text-main-red text-left font-semibold tracking-widest leading-relaxed mt-4 lg:w-fit lg:mx-auto">
              Appareils de paiement connectés
            </p>
            <p className="uppercase font-bold text-left text-[1.5rem] mt-2 lg:text-[2.6rem]">
              DES TARIFS DEFIANT TOUTE CONCURRENCE
            </p>
          </div>
          <div className="flex flex-col space-y-6 lg:space-y-0 lg:flex-row lg:justify-around lg:mt-8 xx:w-[75%] xx:mx-auto">
            <div className="flex flex-col justify-center items-center w-full h-[14rem] mt-8 lg:mt-0 shadow-custom-red lg:w-[16.81rem] lg:h-[14.18rem] lg:px-4">
              <p className="text-main-red text-[3rem] font-semibold">24,99€</p>
              <p className="text-[1.125rem] font-semibold mt-4">
                Coût de location
              </p>
              <p className="text-grey-text text-[0.875rem]">
                Un coût de location bas pour vos terminaux de paiement
              </p>
            </div>
            <div className="flex flex-col justify-center items-center w-full h-[14rem] shadow-custom-red lg:w-[16.81rem] lg:h-[14.18rem] lg:px-4">
              <p className="text-main-red text-[3rem] font-semibold">0€</p>
              <p className="text-[1.125rem] font-semibold mt-4">
                Coût de maintenance
              </p>
              <p className="text-grey-text text-[0.875rem]">
                Tout est sur votre mobile, aucun coût supplémentaire
              </p>
            </div>
            <div className="flex flex-col justify-center items-center w-full h-[14rem] shadow-custom-red lg:w-[16.81rem] lg:h-[14.18rem] lg:px-4">
              <p className="text-main-red text-[3rem] font-semibold">0,7%</p>
              <p className="text-[1.125rem] font-semibold mt-4">
                Coût de transaction
              </p>
              <p className="text-grey-text text-[0.875rem]">
                Un coût de transaction bas défiant toute concurrence
              </p>
            </div>
          </div>
        </div>
        <DynamicSection />
      </div>
      <Footer />
    </>
  );
}
