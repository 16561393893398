import React from 'react';
import Header from './Header';
import { Link } from 'react-router-dom';

export default function HomeVideo() {
  return (
    <div className="container-snap relative h-screen">
      {/* Vidéo en arrière-plan */}
      <video
        className="absolute top-0 left-0 w-full h-full object-cover z-0"
        loop
        muted={true}
        autoPlay={true}
        playsInline
        controls={false}
      >
        <source src="./assets/videos/video2.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
        
      {/* Contenu superposé */}
      <div className="relative h-full z-10 flex  lg:h-full px-[1.5rem] flex-col justify-around lg:items-center pt-[5.5rem] lg:pt-[10rem] xx:pt-[15rem]">
        <div className='flex flex-col justify-start '>
            <h1 className='w-full text-[1.75rem] lg:text-[3rem] lg:w-[38rem] text-white font-poppins text-left font-semibold mt-8'>Un Engagement Sans Faille,<br className='md:hidden'/> Vers <span className='text-main-red'>L'Excellence</span></h1>
            <Link target='_blank' to='https://app.b-partner.com/register' className='h-fit w-fit'>
            <button className="h-[3rem] w-[11.875rem] bg-main-red text-white font-dmSans font-bold rounded md:hidden mt-[2rem]">
                Ouvrir un compte
            </button>
            </Link>
        </div>
        <div className='flex flex-col justify-start lg:w-full lg:pl-[4rem]'>
            <h2 className='w-full text-[1.375rem] lg:text-[2rem] text-white font-poppins text-left'>Un compte multi-devises sans <span className='text-main-red'>frontières</span></h2>
            <p className='text-left font-poppins text-white lg:text-[1.1875rem]'>accompagné de services innovants pour faciliter votre gestion financière.</p>
        </div>
      </div>
    </div>
  );
}
