import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAppContext } from '../contexts/AppContext';

export default function CustomLink({ children, target = null, to, from = '/', className, scrollTo = null }) {
    const { state, updateContext } = useAppContext();
    const navigate = useNavigate();

    const pushFrom = (e) => {
        updateContext("from", from);

        // Si scrollTo est défini, on empêche la navigation par défaut et gère le défilement
        if (scrollTo) {
            e.preventDefault();
            navigate(to);  // Navigue vers le lien
            setTimeout(() => {
                const element = document.getElementById(scrollTo);
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth', block: 'start' });
                }
            }, 0); // Défère le scroll pour attendre la fin de la navigation
        }
    };

    return (
        <Link
            onClick={pushFrom}
            className={`${className} w-fit h-fit`}
            to={to}
            target={target && target}
        >
            {children}
        </Link>
    );
}
