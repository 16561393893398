import React, { useState } from "react";
import { useTpeContext } from "../../contexts/TpeContext";
import useIsMobile from "../../hooks/useIsMobile";
import { getBestOffer, secondOffer } from "./functions";
import { Link } from "react-router-dom";
import SwitchButton from "../SwitchButton";

export default function Final() {
  const tpe = {
    N86: {
      commercial_name: "B Partner Pro",
      printing: true,
      product_client: "Prestige Business",
      contract_duration: "24 mois",
      terminal_purchase_cost: 0,
      terminal_rental_cost: 10.99,
      maintenance_cost: 0,
      volume_cost: 0.007,
      transaction_cost: 0,
      billing_cost: 3297.0,
      img: "./assets/images/tpePro.png",
    },
    N62: {
      commercial_name: "B Partner Flex",
      printing: false,
      product_client: "Prestige Business",
      contract_duration: "24 mois",
      terminal_purchase_cost: 0,
      terminal_rental_cost: 7.99,
      maintenance_cost: 0,
      volume_cost: 0.007,
      transaction_cost: 0,
      billing_cost: 2397.0,
      img: "./assets/images/tpeFlex.png",
    },
    SoftPOS: {
      commercial_name: "B Partner Tap",
      printing: false,
      product_client: "Prestige Business",
      contract_duration: "24 mois",
      terminal_purchase_cost: 0,
      terminal_rental_cost: 0,
      maintenance_cost: 0,
      volume_cost: 0.007,
      transaction_cost: 0,
      billing_cost: 0.0,
      img: "./assets/images/softpos.png",
    },
  };

  const Row = ({ item, formule, id }) => {
    // Vérifie si l'index est pair pour appliquer un style de fond
    const isEven = (num) => num % 2 === 0;

    return (
      <div
        className={`flex justify-between items-center ${
          isEven(id) ? "bg-[#f5f5f67a]" : ""
        } py-2`}
      >
        <p className="text-[0.875rem] text-left">{item.title}</p>
        {item[formule] === "valid" ? (
          <img alt="valid" src="./assets/svg/valid.svg" />
        ) : (
          <p className="text-sm">{item[formule]}</p>
        )}
      </div>
    );
  };

  const isMobile = useIsMobile();
  const { state, updateState } = useTpeContext();
  const handleChoice = (choice) => {
    setChoice(choice);
  };
  const [choice, setChoice] = useState(
    getBestOffer(state.apiResult, state.userInfo)
  );
  const [active, setActive] = useState(true);

  const [selectedDuration, setSelectedDuration] = useState("12 mois");

  const toggleSection = () => {
    setActive(!active);
  };

  const getTabInfos = () => {
    return {
      "Fonction d’impression du terminal": tpe[choice].printing
        ? "valid"
        : "Non",
      "Montant mensuel en EURO": state.userInfo.monthly_amount + "€",
      "Nombre de transactions mensuel": state.userInfo.transaction_count,
      "Coût d’achat du terminal": tpe[choice].terminal_purchase_cost + "€",
      "Coût de location du terminal": tpe[choice].terminal_rental_cost + "€",
      "Coût de maintenance mensuelle": tpe[choice].maintenance_cost + "€",
      "Carte SIM inclue avec l’appareil": "valid",
    };
  };
  return (
    <div className="flex flex-col">
      <p className="uppercase text-left font-bold text-[1.25rem] lg:hidden">
        TERMINAUX DE PAIEMENT MOBILE
      </p>
      <div className="lg:flex lg:flex-row lg:justify-around">
        <div className="lg:w-[20rem] lg:flex lg:flex-col lg:justify-start">
          <p className="text-main-red uppercase text-center mt-8 tracking-wider font-semibold  lg:text-left">
            NOS OFFRES POUR LES Professionels
          </p>
          <p className="uppercase text-center font-bold text-[1.25rem] mt-8 lg:text-[2.6rem] lg:text-left">
            Offre la plus adaptée
          </p>
        </div>

        <div className="lg:w-[30rem]">
          <div className="flex flex-row w-full mx-auto justify-around mt-4">
            <p
              onClick={() => {
                handleChoice(getBestOffer(state.apiResult, state.userInfo));
              }}
              className={`p-2 cursor-pointer ${
                choice === getBestOffer(state.apiResult, state.userInfo) &&
                "border-2 border-main-red text-main-red rounded"
              } font-semibold`}
            >
              {
                tpe[getBestOffer(state.apiResult, state.userInfo)]
                  .commercial_name
              }
            </p>
            <p
              onClick={() => {
                handleChoice(secondOffer(state.apiResult, state.userInfo));
              }}
              className={`p-2 cursor-pointer ${
                choice === secondOffer(state.apiResult, state.userInfo) &&
                "border-2 border-main-red text-main-red rounded"
              } font-semibold`}
            >
              {
                tpe[secondOffer(state.apiResult, state.userInfo)]
                  .commercial_name
              }
            </p>
          </div>

          <div className="flex flex-col justify-center items-center mt-4">
            <img
              alt="tpe"
              src={tpe[choice].img}
              className="w-[11.75rem] h-[11.75rem] object-cover"
            />
            <p className="text-[2.25rem]">
              <span className="text-[0.875rem] text-grey-text">
                à partir de{" "}
              </span>
              {tpe[choice].terminal_rental_cost}€
              <span className="text-[0.875rem] text-grey-text">/mois</span>
            </p>
            <span className="text-[0.875rem] text-grey-text">
              sur base annuelle
            </span>
          </div>
          <Link
            target="_blank"
            className="w-fit h-fit"
            to="https://app.b-partner.com/register"
          >
            <button className="h-[3rem] w-full text-white font-bold rounded bg-main-red mt-4">
              Commander mon terminal
            </button>
          </Link>
        </div>
      </div>
      <div className="flex justify-center mt-8 lg:mt-8">
        {/* <SwitchButton
          value1="12 mois"
          value2="24 mois"
          selectedValue={selectedDuration}
          onClick={(value) => setSelectedDuration(value)}
        /> */}
      </div>
      <div className="flex flex-col space-y-6 lg:space-y-0 lg:flex-row lg:justify-around">
        <div className="flex flex-col justify-center items-center w-full h-[14rem] mt-8 lg:mt-0 shadow-custom-red lg:w-[16.81rem] lg:h-[14.18rem] lg:px-4 lg:order-2">
          <p className="text-[1.125rem] font-semibold mt-4">
            Soit une réduction de
          </p>
          <p className="text-main-red text-[3rem] font-semibold">
            {state.apiResult.Economie?.[choice]}
          </p>
          <p className="text-[1.125rem] font-semibold mt-4">
            Sur votre facture TPE
          </p>
        </div>
        <div className="flex flex-col justify-center items-center w-full h-[14rem] mt-8 lg:mt-0 shadow-custom-red lg:w-[16.81rem] lg:h-[14.18rem] lg:px-4 lg:order-1">
          <p className="text-main-red text-[3rem] font-semibold">
            {parseInt(
              (state.apiResult.data_concurrent[
                "Coût annuel Estimé avec acquisition"
              ] -
                state.apiResult[choice][
                  "Coût annuel Estimé avec acquisition"
                ]) /
                12
            )}{" "}
            €
          </p>
          <p className="text-[1.125rem] font-semibold mt-4">
            D'économie mensuelle
          </p>
        </div>
        <div className="flex flex-col justify-center items-center w-full h-[14rem] mt-8 lg:mt-0 shadow-custom-red lg:w-[16.81rem] lg:h-[14.18rem] lg:px-4 lg:order-3">
          <p className="text-main-red text-[3rem] font-semibold">
            {parseInt(
              state.apiResult.data_concurrent[
                "Coût annuel Estimé avec acquisition"
              ] - state.apiResult[choice]["Coût annuel Estimé avec acquisition"]
            )}{" "}
            €
          </p>
          <p className="text-[1.125rem] font-semibold mt-4">
            D'économie annuelle
          </p>
        </div>
      </div>

      <div className="mt-8">
        {/* <div className="flex justify-end mb-2">
          <img
            alt="upload tpe simulation"
            src="./assets/images/icon-upload.png"
            className="cursor-pointer"
          />
        </div> */}
        <div
          onClick={toggleSection}
          className="flex flex-row w-full border-b py-2 justify-between cursor-pointer"
        >
          <p className="text-[1.125rem] font-semibold">Détail de l'offre</p>
          <img
            alt="toggle button"
            src={"./assets/svg/" + (active ? "less.svg" : "plus.svg")}
          />
        </div>
        <div className={`${!active && "hidden"} space-y-2`}>
          {Object.entries(getTabInfos()).map(([title, value], index) => (
            <Row
              key={index}
              item={{ title, [choice]: value }}
              formule={choice}
              id={index}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
