import React from 'react'
import Retour from '../components/Retour'
import Footer from '../components/Footer'
export default function Activate() {
  return (
    <>
    <div className='pt-[6rem] px-[1.5rem] font-poppins lg:pt-[8rem] lg:pb-16 xx:pl-[8rem]'>
      <Retour page='/bPartnerFidelite' section ='fidelite'/>
      <p className='text-[1.5rem] font-bold text-left uppercase mt-8 lg:text-[2.25rem]'>Activez votre compte B Partner</p>
      <p className='text-left mt-8'>Pour tout compte B Partner ouvert et activé, les clients particuliers et professionnels seront récompensés par l’acquisition de points de Fidélité.
      Les clients particuliers pourront même profiter du double de points de Fidélité s’ils choisissent un abonnement annuel !</p>
      <p className='text-left mt-2'>C’est une excellente opportunité pour maximiser les avantages liés à votre compte. N’hésitez pas à explorer toutes les possibilités offertes par votre carte B Partner et à profiter pleinement de ces privilèges ! 
      </p>
      <div className='flex flex-col w-full space-y-2 py-8 lg:w-4/5 lg:ml-12'>
        <div className='w-full border-b text-grey-text flex flex-row space-x-2 items-center'>
          <p className='w-1/3 text-left'>Offre choisie</p>
          <p className='w-1/3 text-left'>Abonnement mensuel</p>
          <p className='w-1/3 text-left'>Abonnement annuel</p>
        </div>
        <div className='w-full border-b text-black flex flex-row space-x-2 items-center'>
          <p className='w-1/3 text-left'>Premium</p>
          <p className='w-1/3 text-left'><strong>10 pts</strong></p>
          <p className='w-1/3 text-left'><strong>20 pts</strong></p>
        </div>
        <div className='w-full border-b text-black flex flex-row space-x-2 items-center'>
          <p className='w-1/3 text-left'>Premium business</p>
          <p className='w-1/3 text-left'><strong>non applicable</strong></p>
          <p className='w-1/3 text-left'><strong>60 pts</strong></p>
        </div>
        <div className='w-full border-b text-black flex flex-row space-x-2 items-center'>
          <p className='w-1/3 text-left'>Prestige</p>
          <p className='w-1/3 text-left'><strong>20 pts</strong></p>
          <p className='w-1/3 text-left'><strong>40 pts</strong></p>
        </div>
        <div className='w-full border-b text-black flex flex-row space-x-2 items-center'>
          <p className='w-1/3 text-left'>Prestige business</p>
          <p className='w-1/3 text-left'><strong>non applicable</strong></p>
          <p className='w-1/3 text-left'><strong>100 pts</strong></p>
        </div>
      </div>
    </div>
      <Footer />
      </>
  )
}
