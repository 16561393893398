import React from 'react'
import useIsMobile from '../hooks/useIsMobile'
import SectionBpc from '../components/SectionBpc'
import BpcCaroussel from '../components/BpcCaroussel'
import Footer from '../components/Footer'
export default function BPartnerClub() {
    const isMobile = useIsMobile()
  return (
    <div className={`flex flex-col ${!isMobile ? 'h-auto overflow-hidden' : ''}`}>
        <div className={`${!isMobile ? 'scroll-container' : ''}`}>
            <div className="container-snap relative h-screen">
                {/* Vidéo en arrière-plan */}
                <img
                    className="absolute top-0 left-0 w-full h-full object-cover z-0 grayscale"
                    alt='b partner club'
                    src='./assets/images/homebpc.png'
                />
                    
                {/* Contenu superposé */}
                <div className="relative h-full z-10 flex bg-[#000000d5] lg:h-full px-[1.5rem] flex-col justify-around lg:items-center pt-[5.5rem] lg:pt-[10rem] xx:pt-[15rem]">
                    <div className='flex flex-col justify-start '>
                        <p className='text-left uppercase font-bold text-main-red tracking-wider lg:text-center xx:text-[1.5rem]'>B Partner Club</p>
                        <h1 className='w-full text-[1.75rem] lg:text-[3rem] lg:w-[38rem] text-white font-poppins text-left lg:text-center font-semibold mt-8'>UN MONDE HORS NORMES 
                        À VOTRE PORTÉE</h1>
                    </div>
                    <div className='flex flex-col justify-start lg:w-full lg:pl-[4rem]'>
                        <h2 className='w-full text-[1.05rem] lg:text-[2rem] text-white font-poppins text-left'>Un club d’affaires  <span className='text-main-red'>confidentiel</span></h2>
                        <p className='text-left font-poppins text-[0.875rem] leading-[2.25rem] mt-2 text-white lg:text-[1.1875rem]'>Offrant un accès privilégié à des évènements et expériences Uniques et d’Exceptions</p>
                    </div>
                </div>
            </div>
            <SectionBpc 
                    id='avantages'
                    title="DÉCOUVREZ DE NOUVELLES aventures"
                    subTitle="UN MONDE D’EXPÉRIENCES INÉGALÉES"
                    text="Le B Partner Club incarne l’élite, réservé exclusivement aux membres Prestige et Prestige Business. Conçu sur mesure pour répondre aux attentes de notre clientèle la plus exigeante, ce club est un tremplin idéal pour rencontrer et se connecter avec d’autres clients B Partner partageant les mêmes valeurs. En plus de développer votre réseau, le B Partner Club vous offre la possibilité de participer à des événements d’exception en France et en Europe, et d’accéder à un monde de privilèges rares, d’expériences hors du commun et de rencontres inaccessibles. Ce que nous proposons ne se monnaie pas. Ce que nous vous offrons, c’est l’accès à un univers réservé à une poignée de privilégiés, où chaque moment se transforme en une expérience unique, luxueuse et exceptionnelle.
"
                    bulletPoints={[
                    ]}
                    imgName='bpc1.jpeg'
                    altImg='girl walking by'
                    align='left'
                    button={false}
            />
            <BpcCaroussel />
            <SectionBpc 
            id='avantages'
            title="OUVREZ VOTRE RÉSEAU"
            subTitle="SOIRÉES DE GALA ET NETWORKING"
            text={
                <>
                <p>Participez à nos soirées de gala exclusives, conçues pour faciliter les rencontres d’affaires entre nos clients.
                </p>
                <p>Ces événements se déroulent dans des lieux prestigieux et sont réservés exclusivement à nos clients Prestige, garantissant ainsi des échanges de haute qualité.
                </p>
                </>
            }
            bulletPoints={[]}
            imgName='bpc-img2.png'
            altImg='girl walking by'
            align='right'
            button={false}
            />
            <SectionBpc 
            id='avantages'
            title="B Partner club"
            subTitle="REJOIGNEZ L’ÉLITE"
            text={
                <>
                <p>Le B Partner Club ne se contente pas de vous offrir des récompenses. Il vous invite à vivre des moments inestimables, à rencontrer des personnalités influentes, et à accéder à un monde où chaque détail est conçu pour sublimer votre expérience. En tant que membre Prestige, vous bénéficierez de privilèges exceptionnels introuvables ailleurs. Chaque avantage et chaque opportunité vous rapprochent d’un univers réservé aux véritables connaisseurs du luxe.</p>
                <p className='mt-4 font-semibold text-black'>Intéressé à rejoindre le cercle fermé des membres Prestige ?</p>
                <p className='text-black'>Utilisez davantage votre carte B Partner au sein de notre réseau de partenaires et faites le premier pas vers des expériences uniques que seul le B Partner Club peut vous offrir.</p>
                </>
            }
            bulletPoints={[]}
            imgName='bpc-img3.jpeg'
            altImg='girl walking by'
            align='left'
            button={true}
            goTo='https://app.b-partner.com/register'
            blank={true}
            />
            <Footer />
        </div>
    </div>
  )
}
