import React from "react";
import Retour from "../components/Retour";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";

export default function Conciergerie() {
  return (
    <>
      <div className="pt-[6rem] px-[1.5rem] lg:pt-[8rem] xx:pl-[8rem] font-poppins mb-[5rem]">
        <Retour page="/" section="conciergerie" />
        <div
          className="flex flex-col space-y-[2.5rem] lg:mt-[2.5rem]"
          id="conciergerie"
        >
          <div className="">
            <p className="text-main-red uppercase font-poppins tracking-widest w-fit font-semibold mt-4 lg:text-[1.5rem]">
              POUR LES OFFRES PREMIUM
            </p>
            <p className="font-bold text-[1.5rem] w-fit lg:text-[2.25rem]">
              SERVICE CONCIERGERIE
            </p>
            <div className="mt-4 flex flex-col space-y-4 lg:flex-row lg:space-x-[8rem] lg:items-start lg:space-y-0">
              <div className="flex flex-row items-start w-fit">
                <img
                  alt="clock"
                  src="./assets/svg/clock.svg"
                  className="mt-1"
                />
                <div className="flex flex-col text-left ml-3">
                  <p className="">9h à 21h</p>
                  <p className="">6 jours sur 7</p>
                  <p className="text-grey-text">(Hors jours feriés)</p>
                </div>
              </div>
              <div className="flex flex-row items-start w-fit lg:h-full">
                <img
                  alt="phone"
                  src="./assets/svg/phone.svg"
                  className="mt-1"
                />
                <div className="flex flex-col text-left ml-3">
                  <p className="">+33 1 83 81 71 64</p>
                </div>
              </div>
              <div className="flex flex-row items-start w-fit">
                <img
                  alt="help & contact"
                  src="./assets/svg/aide-contact.svg"
                  className="mt-1"
                />
                <div className="flex flex-col text-left ml-3">
                  <p className="">Retour dans un délai de 2h</p>
                </div>
              </div>
            </div>
            <div className="flex flex-col lg:flex-row items-center">
              <div className="text-left mt-[2.5rem] lg:w-[30.5rem] xx:w-[40rem]">
                <p>
                  La Conciergerie B Partner vous permet d’effectuer les demandes
                  suivantes :
                </p>
                <p className="mt-[2rem]">
                  <strong>• Voyages :</strong> organisation de séjours,
                  recommandations et réservations d’hôtels, de billets de train,
                  d’avion, etc. .
                </p>
                <p>
                  <strong>• Gastronomie </strong> recommandations et
                  réservations de restaurants, bars, clubs, organisation de
                  services de traiteur, de chef à domicile, de dégustations,
                  etc.
                </p>
                <p>
                  <strong>• Quotidien</strong> réservations de taxis, véhicules
                  avec chauffeur, services à la personne, livraison de fleurs,
                  démarches administratives.
                </p>
                <p>
                  <strong>• Culture et bien être :</strong> réservations pour
                  des concerts, spectacles, expositions, sélection d’idées
                  cadeaux, recommandations d’activités sportives et de
                  bien-être.
                </p>
                <Link to="https://app.b-partner.com/register" target="_blank">
                  <button className="w-full h-[3rem] text-main-red border-main-red rounded-md font-semibold border-[2px] mt-[2.5rem] lg:w-[18rem]">
                    Souscrire à un compte premium
                  </button>
                </Link>
              </div>
              <img
                src="./assets/images/desktop-section2-home.png"
                alt="man on the phone"
                className="w-full h-[18.5rem] object-cover mt-6 lg:w-[40.25rem] lg:h-[22.81rem] lg:ml-auto xx:w-[50rem] xx:h-[25rem] xx:mr-[6rem]"
              />
            </div>
          </div>
        </div>

        <div
          className="flex flex-col space-y-[2.5rem] lg:mt-[5rem]"
          id="assistant"
        >
          <div className="">
            <p className="text-main-red uppercase font-poppins tracking-widest w-fit font-semibold mt-4 lg:text-[1.5rem]">
              Pour les offres prestige
            </p>
            <p className="font-bold text-[1.5rem] w-fit lg:text-[2.25rem]">
              ASSISTANT PERSONNEL LIFESTYLE MANAGEMENT
            </p>
            <div className="mt-4 flex flex-col space-y-4 lg:flex-row lg:space-x-[8rem] lg:items-start lg:space-y-0">
              <div className="flex flex-row items-start w-fit">
                <img
                  alt="clock"
                  src="./assets/svg/clock.svg"
                  className="mt-1"
                />
                <div className="flex flex-col text-left ml-3">
                  <p className="">24h/24h</p>
                  <p className="">7 jours sur 7</p>
                </div>
              </div>
              <div className="flex flex-row items-start w-fit lg:h-full">
                <img
                  alt="phone"
                  src="./assets/svg/phone.svg"
                  className="mt-1"
                />
                <div className="flex flex-col text-left ml-3">
                  <p className="">+33 1 82 28 12 75</p>
                </div>
              </div>
              <div className="flex flex-row items-start w-fit">
                <img
                  alt="help & contact"
                  src="./assets/svg/aide-contact.svg"
                  className="mt-1"
                />
                <div className="flex flex-col text-left ml-3">
                  <p className="">Retour dans un délai de 2h</p>
                </div>
              </div>
            </div>
            <div className="flex flex-col lg:flex-row items-center">
              <div className="text-justify space-y-4 mt-[2.5rem] lg:w-[30.5rem] xx:w-[40rem]">
                <p>
                  L’Assistant Personnel Lifestyle B Partner est votre allié
                  ultime pour vous simplifier votre quotidien.
                </p>
                <p>
                  Disponible à toute heure, ce service unique répond à toutes
                  vos demandes, des plus simple aux plus sophistiquées.
                </p>
                <p>
                  Que ce soit pour organise vos prochaines vacances,
                  l’anniversaire surprise d’un de vos proches, réserver une loge
                  pour vivre un match important, ou encore vous accompagner lors
                  d’une journée de shopping, l’Assistant Personnel rendra votre
                  expérience exceptionnelle tout en restant discret et attentif.
                </p>
                <p>
                  Il peut également vous recommander les meilleurs restaurants
                  lors de vos déplacements ou organiser votre journée pour que
                  vous puissiez vous concentrer sur vos affaires, votre famille
                  ou ce qui compte vraiment pour vous.
                </p>
                <Link to="https://app.b-partner.com/register" target="_blank">
                  <button className="w-full h-[3rem] text-main-red border-main-red rounded-md font-semibold border-[2px] mt-[2.5rem] lg:w-[18rem]">
                    Souscrire à un compte prestige
                  </button>
                </Link>
              </div>
              <img
                src="./assets/images/img2-conciergerie.png"
                alt="man on the phone"
                className="w-full h-[18.5rem] object-cover mt-6 lg:w-[40.25rem] lg:h-[22.81rem] lg:ml-auto xx:w-[50rem] xx:h-[25rem] xx:mr-[6rem]"
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
