import React from 'react'
import useIsMobile from '../hooks/useIsMobile'
import Carrousel from './Carroussel';
import CardBPC from './CardBPC';
export default function BpcCaroussel() {
    const isMobile = useIsMobile();
  if(isMobile){
    return(
        <div className={`${isMobile ? '' : 'container-snap'} flex flex-col font-poppins px-[1.5rem] lg:justify-center lg:pt-[4rem] mb-12`}>
                    <p className='uppercase text-main-red tracking-wider font-semibold mt-2 lg:tracking-widest xx:text-[1.25rem]'>
                    DÉCOUVREZ LES OPPORTUNITÉS B PARTNER CLUB
                    </p>
                    <p className='font-bold text-[1.25rem] mt-[1.5rem] lg:text-[1.875rem] uppercase'>UN ACCÈS VIP AUX ÉVÉNEMENTS</p>
                    <div className='xx:w-fit xx:mx-auto'>
                      <Carrousel id='valeurs'>
                          <CardBPC 
                          img='./assets/images/bpc-car1.jpeg' 
                          alt={'dishes'} 
                          title={<p className='font-bold text-[1rem]'>Gastronomie</p>}
                          text={"Régalez vos sens dans les restaurants les plus exclusifs de la capitale. Nous vous offrons l’accès à des tables habituellement inaccessibles, où la haute gastronomie se transforme en une expérience intime."}
                          subText={[['handshake',"Restaurants partenaires : Girafe, Le Piaf, Maxim's, MUN, Louie, Maison Revka, et bien d'autres restaurants d’exception du groupe Paris Society."]]}
                          />
                          <CardBPC 
                          img='./assets/images/bpc-car2.jpeg'
                          alt={'prosche'} 
                          title={<p className='font-bold text-[1rem]'>Sport & automobile</p>}
                          text={"Vivez les plus grands événements sportifs au cœur de l’action, depuis des loges privées. Ressentez l’adrénaline des essais de voitures de luxe sur circuit de Formule 1 ou participez à des dîners de galas exclusifs avec les marques automobiles les plus prestigieuses."}
                          subText={[['handshake',"Partenaires : Ferrari, Porsche, Lamborghini, Bentley, Maserati, McLaren, Aston Martin."],['ticket', "Accès exclusifs : Matchs de la Ligue des Champions, Rencontres à Roland Garros, Paddock du Grand Prix de Spa-Francorchamps, Ryder Cup, et plus encore."]]}
                          />
                          <CardBPC 
                          img='./assets/images/bpc-car3.jpeg' 
                          alt={'man on laptop'} 
                          title={<p className='font-bold text-[1rem]'>Voyage & vacances</p>}
                          text={"Évadez-vous vers des destinations de rêve avec des séjours offerts dans des hôtels prestigieux ou des sur-classements garantis auprès des plus grandes compagnies aériennes."}
                          subText={[['handshake',"Partenaires : FShangri-La, Mandarin Oriental, Air France-KLM, Club Med, et Center Parcs."]]}
                          />
                          <CardBPC 
                          img='./assets/images/bpc-car4.jpeg' 
                          alt={'man on laptop'} 
                          title={<p className='font-bold text-[1rem]'>Maison & décoration </p>}
                          text={"Découvrez des univers créatifs uniques lors de prévisualisations exclusives réservées à nos membres, en compagnie des plus grands designers et architectes."}
                          subText={[['handshake',"Créateurs de renom : Charles Zana, Dorothée Meilichzon, Sarah Lavoine, Jean Louis Deniot, Didier Gomez."]]}
                          />
                        <CardBPC 
                          img='./assets/images/bpc-car5.jpeg' 
                          alt={'party'} 
                          title={<p className='font-bold text-[1rem]'>Mode</p>}
                          text={"Recevez des invitations pour des événements de mode exclusifs, en France ou à l'étranger : défilés de haute couture, présentations de collections, soirées de lancement et ventes privées. Profitez de l’accompagnement de nos Personal Shoppers pour une expérience unique."}
                          subText={[['profil',"Un personal shopper vous sera entièrement dédié pour vous accompagner dans vos sorties shopping afin de vous conseiller au mieux."],['handshake', "Chanel, Louis Vuitton, Hermès, Rolex, Audemars Piguet, et bien d'autres maisons emblématiques."]]}
                          />
                          <CardBPC 
                          img='./assets/images/bpc-car6.jpeg' 
                          alt={'party'} 
                          title={<p className='font-bold text-[1rem]'>Lifestyle</p>}
                          text={"Profitez de votre statut VIP pour assister à des concerts depuis les meilleures loges ou pour accéder à des clubs privés réservés à l’élite."}
                          subText={[['ticket',"Événements et clubs exclusifs : Accor Arena, Paris La Défense Arena, Moulin Rouge, Crazy Horse, Jamel Comedy Club, et les établissements du groupe Paris Society."]]}
                          />
                      </Carrousel>
                </div>
            </div>
    )
  }else{
    return(
      <>
      <div className="container-snap relative h-screen py-[6rem]">
        <p className='uppercase text-main-red tracking-wider font-semibold mt-2 lg:tracking-widest xx:text-[1.25rem]'>
        DÉCOUVREZ LES OPPORTUNITÉS B PARTNER CLUB
        </p>
        <p className='font-bold text-[1.25rem] mt-[1.5rem] lg:text-[1.875rem] xx:text-[2.5rem] uppercase'>UN ACCÈS VIP AUX ÉVÉNEMENTS</p>
        <div className='flex flex-row mt-8 justify-around xx:pt-[5rem] xx:px-[5rem]'>
          <CardBPC 
            img='./assets/images/bpc-car1.jpeg' 
            alt={'dishes'} 
            title={<p className='font-bold text-[1.5rem]'>Gastronomie</p>}
            text={"Régalez vos sens dans les restaurants les plus exclusifs de la capitale. Nous vous offrons l’accès à des tables habituellement inaccessibles, où la haute gastronomie se transforme en une expérience intime."}
            subText={[['handshake',"Restaurants partenaires : Girafe, Le Piaf, Maxim's, MUN, Louie, Maison Revka, et bien d'autres restaurants d’exception du groupe Paris Society."]]}
          />
          <CardBPC 
            img='./assets/images/bpc-car2.jpeg'
            alt={'prosche'} 
            title={<p className='font-bold text-[1.5rem]'>Sport & automobile</p>}
            text={"Assistez aux plus grands événements sportifs depuis des loges privées. Ressentez l’adrénaline des essais de voitures de luxe sur circuits de Formule 1, ou participez à des dîners de gala exclusifs avec les marques automobiles les plus prestigieuses."}
            subText={[['handshake',"Partenaires : Ferrari, Porsche, Lamborghini, Bentley, Maserati, McLaren, Aston Martin."],['ticket', "Accès exclusifs : Matchs de la Ligue des Champions, Rencontres à Roland Garros, Paddock du Grand Prix de Spa-Francorchamps, Ryder Cup, et plus encore."]]}
          />
          <CardBPC 
            img='./assets/images/bpc-car3.jpeg' 
            alt={'man on laptop'} 
            title={<p className='font-bold text-[1.5rem]'>Voyage & vacances</p>}
            text={"Évadez-vous vers des destinations de rêve avec des séjours offerts dans des hôtels prestigieux ou des sur-classements garantis auprès des plus grandes compagnies aériennes."}
            subText={[['handshake',"Partenaires : Shangri-La, Mandarin Oriental, Air France-KLM, Club Med, et Center Parcs."]]}
          />
    </div>
</div>
<div className="container-snap relative h-screen pt-[6rem] flex flex-row justify-around items-center xx:px-[5rem]">
<CardBPC 
    img='./assets/images/bpc-car4.jpeg' 
    alt={'man on laptop'} 
    title={<p className='font-bold text-[1.5rem]'>Maison & décoration </p>}
    text={"Découvrez des univers créatifs uniques lors de prévisualisations exclusives réservées à nos membres, en compagnie des plus grands designers et architectes."}
    subText={[['handshake',"Créateurs de renom : Charles Zana, Dorothée Meilichzon, Sarah Lavoine, Jean Louis Deniot, Didier Gomez."]]}
  />
  <CardBPC 
    img='./assets/images/bpc-car5.jpeg' 
    alt={'party'} 
    title={<p className='font-bold text-[1.5rem]'>Mode</p>}
    text={"Recevez des invitations pour des événements de mode exclusifs : défilés, présentations, soirées de lancement et ventes privées, avec l’accompagnement de nos Personal Shoppers pour une expérience unique."}
    subText={[['profil',"Un personal shopper vous sera entièrement dédié pour vous accompagner dans vos sorties shopping afin de vous conseiller au mieux."],['handshake', "Chanel, Louis Vuitton, Hermès, Rolex, Audemars Piguet, et bien d'autres maisons emblématiques."]]}
  />
  <CardBPC 
    img='./assets/images/bpc-car6.jpeg' 
    alt={'party'} 
    title={<p className='font-bold text-[1.5rem]'>Lifestyle</p>}
    text={"Profitez de votre statut VIP pour assister à des concerts depuis les meilleures loges ou pour accéder à des clubs privés réservés à l’élite."}
    subText={[['ticket',"Événements et clubs exclusifs : Accor Arena, Paris La Défense Arena, Moulin Rouge, Crazy Horse, Jamel Comedy Club, et les établissements du groupe Paris Society."]]}
  />
</div>
</>
    )
  }
}
