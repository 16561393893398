import React from 'react';
import useIsMobile from '../hooks/useIsMobile';

function isEven(number) {
  return number % 2 === 0;
}

const Row = ({ item, formule, id }) => {
    const isMobile = useIsMobile();
    if(isMobile){
        return (
            <div className={`flex justify-between items-center ${isEven(id) ? 'bg-[#f5f5f67a]' : ''}`}>
              <p className="text-[0.875rem] text-left">{item.title}</p>
              {item[formule] === 'valid' ? <img alt='valid' src='./assets/svg/valid.svg' className=''/> : <p className="text-sm">{item[formule]}</p>}    
            </div>
          );
    }else{
        return (
            <div className={`flex justify-between items-center ${isEven(id) ? 'bg-[#f5f5f67a]' : ''}`}>
              <p className="text-[0.875rem] text-left w-[21rem]">{item.title}</p>
              {item['premium'] === 'valid' ? <div className=' w-1/3 flex flex-col items-center'><img alt='valid' src='./assets/svg/valid.svg' className=''/></div> : <p className="text-sm  w-1/3">{item['premium']}</p>}
              {item['prestige'] === 'valid' ? <div className=' w-1/3 flex flex-col items-center'><img alt='valid' src='./assets/svg/valid.svg' className=''/></div> : <p className="text-sm  w-1/3">{item['prestige']}</p>}      
            </div>
          );
    }
  
};

export default function Tableau({ sectionName, data, formule, isActive, toggleSection }) {
  return (
    <div className="space-y-2">
      <div onClick={toggleSection} className="flex flex-row w-full border-b py-2 justify-between cursor-pointer">
        <p className="text-[1.125rem] font-semibold">{sectionName}</p>
        <img alt="toggle button" src={'./assets/svg/' + (isActive ? 'less.svg' : 'plus.svg')} />
      </div>
      <div className={`${!isActive && 'hidden'} space-y-2`}>
        {data.map((item, index) => (
          <Row key={index} item={item} formule={formule} id={index} />
        ))}
      </div>
    </div>
  );
}
