import React from "react";
import useIsMobile from "../hooks/useIsMobile";
import { Link } from "react-router-dom";
import SectionParticulier from "../components/SectionParticulier";
import Carrousel from "../components/Carroussel";
import CardParticulier from "../components/CardParticulier";
import Comparatif from "../components/Comparatif";
import Section from "../components/Section";
import Footer from "../components/Footer";
import CustomLink from "../components/CustomLink";
export default function Particulier() {
  const isMobile = useIsMobile();
  return (
    <div
      className={`flex flex-col font-poppins ${
        !isMobile ? "h-auto overflow-hidden" : ""
      }`}
    >
      <div className={`${!isMobile ? "scroll-container" : ""}`}>
        <div className="container-snap relative h-screen">
          <img
            className="absolute left-0 w-full h-[100%] object-cover z-0"
            src="./assets/images/fondParticulier.png"
          />

          <div className="relative z-10 flex px-[1.5rem] lg:px-[3rem] xx:px-[6rem] flex-col pt-[22%] lg:pt-[8%] xx:pt-[5%] text-white h-screen justify-end lg:justify-start">
            <p className="text-[1.75rem] font-semibold leading-[2.45rem] text-left lg:text-[2rem] lg:w-[32.0625rem] lg:mt-[5rem] xx:text-[2.75rem] xx:w-[45.0625rem] xx:leading-[3.5rem]">
              Optez pour une gestion financière{" "}
              <span className="text-main-red">multidevises</span> optimale avec
              un accompagnement personnalisé.
            </p>
            <p className="font-dmSans leading-[1.6875rem] font-medium text-left lg:mt-[1.25rem] lg:w-[32.0625rem] xx:w[45.0625rem] xx:text-[1.125rem]">
              Simplifiez vos transactions, gérez des comptes en différentes
              devises et réduisez vos coûts.
            </p>
            <CustomLink
              from="/particuliers"
              className="relative w-fit h-fit mt-[2rem] mb-[2rem] lg:w-[14.5rem]"
              to="/particuliers"
              scrollTo={"comparatifPart"}
            >
              <button className="w-[13.875rem] h-[3rem] bg-white text-black font-dmSans font-bold rounded">
                Découvrir nos offres
              </button>
              <img
                alt="chevron right"
                src="./assets/svg/chevron-right-red.svg"
                className="absolute right-3 top-[15.5px] lg:right-5 lg:top-[16px]"
              />
            </CustomLink>
          </div>
        </div>
        <SectionParticulier
          id="bpClubSection"
          title={
            <>
              notre offre <span className="text-main-red">prestige</span>
            </>
          }
          lign="des services qui vous mènent vers l'excellence"
          subTitle="UN COMPTE POUR UNE CLIENTÈLE EXIGEANTE"
          text="B Partner Prestige est une offre personnalisée pour une clientèle perfectionniste. Nos services sont conçus pour simplifier votre vie et vous permettre de vous concentrer sur l’essentiel. Profitez d’une relation privilégiée et de services sur mesure qui reflètent votre succès et vos ambitions. Simplifiez votre quotidien et bénéficiez d’un confort inégalé à tout moment."
          imgName="img1-particulier.png"
          textBtn="Souscrire"
          altImg="woman checking her account"
          align="left"
          goTo="https://app.b-partner.com/register"
          blank={true}
        />
        <div
          className={`${
            isMobile ? "" : "container-snap"
          } flex flex-col font-poppins px-[1.5rem] lg:justify-center lg:pt-[4rem] mb-12`}
        >
          <p className="uppercase text-main-red tracking-wider font-semibold mt-2 lg:tracking-widest xx:text-[1.25rem]">
            Des services d'exception
          </p>
          <p className="font-bold text-[1.25rem] mt-[1.5rem] lg:text-[1.875rem] uppercase">
            Inclus dans l'offre prestige
          </p>
          <div className="xx:w-fit xx:mx-auto">
            <Carrousel id="valeurs">
              <CardParticulier
                img="./assets/images/card1-car2.png"
                alt={"reception"}
                title={
                  <p className="font-bold text-[1rem]">Assistant personnel</p>
                }
                text={
                  "Un service luxueux afin de répondre à vos demandes de réservations de vol, d‘hôtel, chauffeur personnel ou toute autre requête. L’assistant personnel est joignable 24h/24 tous les jours de la semaine pour vous faciliter la vie."
                }
                goTo={"/assistant"}
                from="/particuliers#valeurs"
              />
              <CardParticulier
                img="./assets/images/card2-car2.png"
                alt={"party"}
                title={<p className="font-bold text-[1rem]">B Partner Club</p>}
                text={
                  "L’offre Prestige vous ouvre les portes d’un Club d’Affaires Privé, idéal pour développer votre réseau et vos opportunités professionnelles. Le B Partner Club vous donne également accès à des lieux et événements exclusifs, soigneusement sélectionnés pour nos clients."
                }
                goTo={"/bPartnerClub"}
                from="/particuliers#valeurs"
              />
              <CardParticulier
                img="./assets/images/card3-car2.png"
                alt={"woman"}
                title={
                  <p className="font-bold text-[1rem]">B Partner Fidélité</p>
                }
                text={
                  "Notre programme de fidélité est pensé pour vous récompenser. Gagnez des points de fidélité, accumulez-les et utilisez-les à votre guise."
                }
                goTo={"/bPartnerFidelite"}
                from="/particuliers#valeurs"
              />
              <CardParticulier
                img="./assets/images/section3-home.png"
                alt={"man on laptop"}
                title={
                  <p className="font-bold text-[1rem]">Paiements en devise</p>
                }
                text={
                  "Gérer vos paiements en devises directement via l’application B Partner.  Effectuez des transactions dans plus de 50 devises et vers plus de 100 pays avec des frais réduits. Suivez vos paiements en temps réel sur votre smartphone ou votre ordinateur. "
                }
                goTo={"/devises"}
                from="/particuliers#valeurs"
              />
              <CardParticulier
                img="./assets/images/card5-car2.png"
                alt={"man on laptop"}
                title={
                  <p className="font-bold text-[1rem]">Conseiller financier</p>
                }
                text={
                  "Nos experts financiers et patrimoniaux sont à votre écoute pour établir un bilan personnalisé de vos projets. Que ce soit en immobilier, en épargne financière ou dans d’autres types de placements, nos conseillers sont là pour vous accompagner."
                }
                goTo={"/conseiller"}
                from="/particuliers#valeurs"
              />
            </Carrousel>
          </div>
        </div>
        <SectionParticulier
          id="bpClubSection"
          title={
            <>
              notre offre <span className="text-main-red">premium</span>
            </>
          }
          lign="l'efficacité chaque jour"
          subTitle="UNE OFFRE ADAPTÉE À VOTRE QUOTIDIEN"
          text="Découvrez l’Offre Premium, spécialement conçue pour vous offrir une expérience enrichie et personnalisée. Bénéficiez d’un accompagnement dédié, d’avantages exclusifs et de solutions adaptées à vos besoins. L’Offre Premium vous permet d’accéder à des services sur-mesure, pour gérer vos finances avec confort et simplicité, tout en profitant d’une relation client privilégiée."
          imgName="img2-particulier.png"
          textBtn="Souscrire"
          altImg="woman checking her account"
          align="right"
          goTo="https://app.b-partner.com/register"
          blank={true}
        />
        <div
          className={`${
            isMobile ? "" : "container-snap"
          } flex flex-col font-poppins px-[1.5rem] lg:justify-center lg:pt-[4rem] mb-12`}
        >
          <p className="uppercase text-main-red tracking-wider font-semibold mt-2 lg:tracking-widest xx:text-[1.25rem]">
            DES SERVICES ADAPTÉS
          </p>
          <p className="font-bold text-[1.25rem] mt-[1.5rem] lg:text-[1.875rem] uppercase">
            INCLUS DANS L’OFFRE PREMIUM
          </p>
          <div className="xx:w-fit xx:mx-auto">
            <Carrousel id="valeurs2">
              <CardParticulier
                img="./assets/images/card1-car2.png"
                alt={"reception"}
                title={
                  <p className="font-bold text-[1rem]">Services conciergerie</p>
                }
                text={
                  "Nos concierges B Partner seront à vos écoute afin de répondre à vos sollicitions. Pour l’organisation de vos prochaines vacances ou pour réserver une table dans un restaurant prisé, faites appel à votre Conciergerie B Partner"
                }
                goTo={"/conciergerie"}
                from="/particuliers#valeurs2"
              />
              <CardParticulier
                img="./assets/images/card2-car2.png"
                alt={"party"}
                title={
                  <p className="font-bold text-[1rem]">B Partner Fidélité</p>
                }
                text={
                  "L’offre Prestige vous ouvre les portes d’un Club d’Affaires Privé, idéal pour développer votre réseau et vos opportunités professionnelles. Le B Partner Club vous donne également accès à des lieux et événements exclusifs, soigneusement sélectionnés pour nos clients."
                }
                goTo={"/bPartnerFidelite"}
                from="/particuliers#valeurs2"
              />
              <CardParticulier
                img="./assets/images/section3-home.png"
                alt={"man on laptop"}
                title={
                  <p className="font-bold text-[1rem]">Paiements en devise</p>
                }
                text={
                  "Gérer vos paiements en devises directement via l’application B Partner.  Effectuez des transactions dans plus de 50 devises et vers plus de 100 pays avec des frais réduits. Suivez vos paiements en temps réel sur votre smartphone ou votre ordinateur. "
                }
                goTo={"/devises"}
                from="/particuliers#valeurs2"
              />
              <CardParticulier
                img="./assets/images/conseiller.png"
                alt={"man on laptop"}
                title={
                  <p className="font-bold text-[1rem]">Conseiller financier</p>
                }
                text={
                  "Nos experts financiers et patrimoniaux sont à votre écoute pour établir un bilan personnalisé de vos projets. Que ce soit en immobilier, en épargne financière ou dans d’autres types de placements, nos conseillers sont là pour vous accompagner."
                }
                goTo={"/conseiller"}
                from="/particuliers#valeurs2"
              />
            </Carrousel>
          </div>
        </div>
        <Comparatif />
        <Section
          id="sectionReputation"
          title="B Partner FIDÉLITÉ"
          subTitle="DÉPENSEZ TOUT EN ÉTANT RÉCOMPENSÉ"
          text="Le B Partner Club vous permet d’obtenir de nombreux privilèges comme des réductions chez nos partenaires ou des avantages B Partner exclusifs."
          bulletPoints={[
            <p>
              Profitez de <span className="font-bold">réductions</span> chez nos
              partenaires
            </p>,
            <p>
              Augmentez de statut et{" "}
              <span className="font-bold">multipliez vos points</span>{" "}
            </p>,
            <p>
              Cumulez du <span className="font-bold">cashback</span> en
              utilisant votre carte{" "}
            </p>,
          ]}
          imgName="section1-home.png"
          altImg="woman checking her account"
          align="right"
          goTo="/bPartnerFidelite"
        />
        <Footer />
      </div>
    </div>
  );
}
