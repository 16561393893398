import React, { useState, useRef, useEffect } from 'react';

export default function Carrousel({ children, title = null, id='valeurs' }) {
    const [translate, setTranslate] = useState(0);
    const [startX, setStartX] = useState(0);
    const [isDragging, setIsDragging] = useState(false);
    const [cardWidth, setCardWidth] = useState(0);
    const [maxTranslate, setMaxTranslate] = useState(0);
    const [isMobile, setIsMobile] = useState(false); // Nouvel état pour vérifier si on est sur mobile
    const gap = 20;
    const containerRef = useRef(null);
    const cardRef = useRef(null);

    // Vérifier la largeur de l'écran pour définir si c'est mobile ou desktop
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768); // Définir une largeur pour mobile (par ex., 768px)
        };

        handleResize(); // Initialiser au chargement
        window.addEventListener('resize', handleResize); // Écouter le redimensionnement

        return () => window.removeEventListener('resize', handleResize); // Nettoyer l'écouteur
    }, []);

    useEffect(() => {
        if (cardRef.current) {
            const cardWidth = cardRef.current.offsetWidth;
            setCardWidth(cardWidth);
        }
    }, [children]);

    useEffect(() => {
        if (containerRef.current && cardWidth > 0) {
            const containerWidth = containerRef.current.offsetWidth;
            const totalWidth = (cardWidth + gap) * React.Children.count(children);
            setMaxTranslate(containerWidth - totalWidth);
        }
    }, [cardWidth, children]);

    const handleMouseDown = (e) => {
        if (!isMobile) return; // Désactiver le glissement sur desktop
        setIsDragging(true);
        setStartX(e.clientX);
    };

    const handleMouseMove = (e) => {
        if (!isDragging || !isMobile) return;

        const currentX = e.clientX;
        const diffX = startX - currentX;

        if (diffX !== 0) {
            setTranslate((prevTranslate) => {
                let newTranslate = prevTranslate - diffX;
                if (newTranslate > 0) newTranslate = 0;
                if (newTranslate < maxTranslate) newTranslate = maxTranslate;
                return newTranslate;
            });
        }
        setStartX(currentX);
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    const handleTranslate = (direction) => {
        const moveDistance = cardWidth + gap;
        if (direction === 'right') {
            setTranslate((prevTranslate) => {
                const newTranslate = prevTranslate - moveDistance;
                return newTranslate < maxTranslate ? maxTranslate : newTranslate;
            });
        } else if (direction === 'left') {
            setTranslate((prevTranslate) => {
                const newTranslate = prevTranslate + moveDistance;
                return newTranslate > 0 ? 0 : newTranslate;
            });
        }
    };

    const canSlideLeft = translate < 0;
    const canSlideRight = translate > maxTranslate;

    return (
        <div className={` ${!(canSlideLeft || canSlideRight) ? 'mt-6' : ''}`} id={id}>
            <div className="flex flex-row mt-[1.5rem]">
                {title !== null && <>{title}</>}
                {(canSlideLeft || canSlideRight) && (
                    <div id="buttons" className="flex flex-row w-[4rem] justify-around ml-auto mb-4">
                        <img
                            className={`cursor-pointer ${canSlideLeft ? '' : 'opacity-50'}`}
                            onClick={() => handleTranslate('left')}
                            src='./assets/svg/arrow-left-car.svg'
                            alt="Left arrow"
                            style={{ filter: canSlideLeft ? 'none' : 'grayscale(100%)' }}
                        />
                        <img
                            className={`cursor-pointer ${canSlideRight ? '' : 'opacity-50'}`}
                            onClick={() => handleTranslate('right')}
                            src='./assets/svg/arrow-right-car.svg'
                            alt="Right arrow"
                            style={{ filter: canSlideRight ? 'none' : 'grayscale(100%)' }}
                        />
                    </div>
                )}
            </div>

            <div
                className="w-full overflow-hidden"
                onMouseDown={isMobile ? handleMouseDown : null}
                onMouseMove={isMobile ? handleMouseMove : null}
                onMouseUp={isMobile ? handleMouseUp : null}
                onMouseLeave={isMobile ? handleMouseUp : null}
            >
                <div
                    ref={containerRef}
                    className="flex transition-transform duration-300 ease-out"
                    style={{ transform: `translateX(${translate}px)` }}
                >
                    {React.Children.map(children, (child, index) => (
                        <div
                            ref={index === 0 ? cardRef : null}
                            style={{ marginRight: `${gap}px` }}
                        >
                            {child}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
