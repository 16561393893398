import React from "react";
import CustomLink from "./CustomLink";

const SimulateTpeCost = () => {
  return (
    <CustomLink
      scrollTo="simulateur"
      className="w-full fixed top-[5.1875rem] lg:top-[5.5rem] z-20 flex flex-row items-center px-[1rem] xx:px-[2rem] bg-main-red h-[3.1rem] lg:h-[5rem] cursor-pointer"
    >
      <img
        alt="simulate tpe cost"
        src="./assets/images/icon-go-simulate-tpe-cost.png"
      />
      <span className="ml-3 font-bold text-white lg:text-[1.3rem] text-[1rem]">
        SIMULER LE COÛT DE VOTRE TPE
      </span>
    </CustomLink>
  );
};

export default SimulateTpeCost;
