import React, { useEffect, useState } from 'react';
import { useTpeContext } from '../../contexts/TpeContext';
import { getBestOffer } from './functions';

export default function Step2() {
  const { state, updateState } = useTpeContext();
  const [reduction, setReduction] = useState(null);

  useEffect(() => {
    if (state.apiResult) {
      const bestOfferKey = getBestOffer(state.apiResult, state.userInfo);
      const reductionPercentage = state.apiResult.Economie?.[bestOfferKey];
      setReduction(reductionPercentage);
    }
  }, [state.apiResult]); // Réagit aux changements de `apiResult`
  return (
    <div className="flex flex-col">
        <p className="text-left font-bold text-[1.25rem]">
          Nous vous proposons une réduction de {reduction} de votre facture TPE
        </p>
        <p className='text-grey-text text-left'>Cette proposition se base sur les données que vous nous avez partagé</p>
        <div className='flex flex-col space-y-6 lg:space-y-0 lg:flex-row lg:justify-around lg:mt-8'>
            <div className='flex flex-col justify-center items-center w-full h-[14rem] mt-8 lg:mt-0 shadow-custom-red lg:w-[16.81rem] lg:h-[14.18rem] lg:px-4 lg:order-2'>
              <p className='text-[1.125rem] font-semibold mt-4'>Soit une réduction de</p>
              <p className='text-main-red text-[3rem] font-semibold'>{reduction && reduction.replace('-','')}</p>
              <p className='text-[1.125rem] font-semibold mt-4'>Sur votre facture TPE</p>
            </div>
            <div className='flex flex-col justify-center items-center w-full h-[14rem] mt-8 lg:mt-0 shadow-custom-red lg:w-[16.81rem] lg:h-[14.18rem] lg:px-4 lg:order-1'>
              <p className='text-main-red text-[3rem] font-semibold'>{parseInt((state.apiResult.data_concurrent['Coût annuel Estimé avec acquisition'] - state.apiResult[getBestOffer(state.apiResult, state.userInfo)]['Coût annuel Estimé avec acquisition']) / 12)} €</p>
              <p className='text-[1.125rem] font-semibold mt-4'>D'économie mensuelle</p>
            </div>
            <div className='flex flex-col justify-center items-center w-full h-[14rem] mt-8 lg:mt-0 shadow-custom-red lg:w-[16.81rem] lg:h-[14.18rem] lg:px-4 lg:order-3'>
              <p className='text-main-red text-[3rem] font-semibold'>{parseInt(state.apiResult.data_concurrent['Coût annuel Estimé avec acquisition'] - state.apiResult[getBestOffer(state.apiResult, state.userInfo)]['Coût annuel Estimé avec acquisition'])} €</p>
              <p className='text-[1.125rem] font-semibold mt-4'>D'économie annuelle</p>
            </div>
          </div>
          <button onClick={()=>{updateState({ step: state.step + 1 });}} className='h-[3rem] bg-main-red rounded text-white font-bold mt-8 lg:ml-auto lg:w-[14rem]'>Voir les détails</button>
    </div>
  );
}
