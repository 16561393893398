import React from 'react'
import useIsMobile from '../hooks/useIsMobile'
import { Link } from 'react-router-dom'

export default function OldSection() {
    const isMobile = useIsMobile()
  return (
    <div className={`${isMobile ? '' : 'container-snap'} flex flex-col lg:flex-row font-poppins px-[1.5rem] mt-[3rem] lg:mt-0 lg:justify-center lg:pt-[0rem] mb-16 lg:bg-black text-white lg:items-center`}>
        <div className='flex flex-col justify-start py-8 bg-black px-4 lg:order-2 lg:w-[31rem]'>
            <p className='text-main-red uppercase font-bold text-left'>Nos offres</p>
            <p className='text-[2rem] text-left mt-4 lg:text-[2.5rem] xx:text-[3rem]'>Souscrivez à une offre adaptée à 
            vos besoins</p>
            <p className='text-left mt-4'>L'excellence et la personnalisation au cœur de nos services. Vivez l'expérience d'une gestion financière 
            fluide, efficace, simple et transparente.</p>
            <p className='text-left mt-4'>Chez B Partner, nous comprenons les rythmes irréguliers et les besoins fluctuants de nos clients d'exception.</p>
            <Link to='https://app.b-partner.com/register' target='_blank' className='w-[12rem] mr-auto mt-8'>
            <button className='h-[3rem] w-full text-main-red border-2 border-main-red font-semibold '>Souscrire</button>
            </Link>
        </div>

        <div className='py-8 lg:order-1 lg:flex lg:flex-row lg:items-center'>
            <div className='w-full h-[23.75rem] gradient-ctn p-2 z-10 lg:w-[25rem] xx:h-[25rem]'>
                <div className='w-full h-full bg-[#1A1717] py-16 px-4 text-left flex flex-col lg:px-8 justify-around xx:px-12'>
                    <div>
                        <p className='font-semibold text-[1.5rem] font-dmSans'>Particuliers</p>
                        <p className='font-dmSans'>Une offre unique, personnalisée et à votre image</p>
                    </div>
                    <div>
                        <p className='font-semibold text-[1.5rem] font-dmSans'>Professionnels</p>
                        <p className='font-dmSans'>Des solutions flexibles pour faciliter votre gestion</p>
                    </div>
                </div>
            </div>
            <img alt='carte b partner' src='./assets/images/imgCard-1.png' className='w-full h-[27rem] lg:h-[34rem] xx:h-[38rem] lg:w-[28rem] grayscale mt-8 lg:-translate-x-16'/> 
        </div>
    </div>
  )
}
