// src/pages/Home.js
import React from "react";
import HomeVideo from "../components/HomeVideo";
import Section from "../components/Section";
import useIsMobile from "../hooks/useIsMobile";
import SectionOffers from "../components/SectionOffers";
import Carrousel from "../components/Carroussel";
import Card from "../components/Card";
import Footer from "../components/Footer";
export default function Home() {
  const isMobile = useIsMobile();

  return (
    <div
      className={`flex flex-col ${!isMobile ? "h-auto overflow-hidden" : ""}`}
    >
      <div className={`${!isMobile ? "scroll-container" : ""}`}>
        <HomeVideo />

        <Section
          id="bpClubSection"
          title="B partner fidélité"
          subTitle="DÉPENSEZ TOUT EN ÉTANT RÉCOMPENSÉ"
          text="Accédez à des privilèges exclusifs avec B Partner Fidélité :"
          bulletPoints={[
            <p>
              <span className="font-bold">Récompenses à l’ouverture :</span>{" "}
              Recevez une récompense dès l’ouverture de votre compte.
            </p>,
            <p>
              <span className="font-bold">Réductions partenaires :</span>{" "}
              Bénéficiez de remises exclusives sur achats, services et
              expériences.
            </p>,
            <p>
              <span className="font-bold">Cashback cumulé :</span> Utilisez
              votre carte B Partner et accumulez du cashback à chaque achat.
            </p>,
          ]}
          imgName="section1-home.png"
          altImg="woman checking her account"
          align="left"
          goTo="/bPartnerFidelite"
          from="/#bpClubSection"
        />

        <Section
          id="conciergerie"
          title="Notre service conciergerie"
          subTitle="UN SERVICE SUR-MESURE, PARTOUT ET À TOUT MOMENT"
          text="Profitez d'un service de conciergerie personnalisé, disponible où et quand vous en avez besoin :"
          bulletPoints={[
            <p>
              <span className="font-bold">Réservation de services :</span> Nous
              réservons taxis, restaurants, vols et hôtels pour vous.
            </p>,
            <p>
              <span className="font-bold">Disponibilité 7j/7 :</span> Service
              accessible tous les jours, en continu.
            </p>,
            <p>
              <span className="font-bold">Réponse rapide :</span> Réponse
              garantie sous 2 heures. Profitez d’une assistance sans contraintes
              incluse dans toutes nos offres.
            </p>,
          ]}
          imgName="section2-home.png"
          altImg="woman checking her account"
          align="right"
          goTo="/conciergerie"
          from="/#conciergerie"
        />

        <Section
          id="paiementMultiDevise"
          title="Paiements multi-devises"
          subTitle="PAYEZ DANS PLUS DE 50 DEVISES"
          text="Nous vous offrons une solution pratique et sécurisée pour gérer vos finances à l’international. Notre service simplifie vos transactions, réduit vos coûts et vous apporte plus de flexibilité."
          bulletPoints={[
            <p>
              Effectuez des virements dans plus de{" "}
              <span className="font-bold">50 devises</span>.
            </p>,
            <p>Utilisez un seul compte pour plusieurs devises.</p>,
            <p>
              Une <span className="font-bold">transparence</span> des frais.
            </p>,
          ]}
          imgName="section3-home.png"
          altImg="man checking his account"
          align="left"
          goTo="/devises"
          from="/#paiementMultiDevise"
        />

        <Section
          id="tpe"
          title="Les Terminaux de paiement"
          subTitle="DES TERMINAUX DE PAIEMENT À DES TAUX ULTRAS COMPÉTITIFS"
          text="Notre gamme de terminaux de paiement offre une amélioration significative de l’expérience client, une optimisation des processus commerciaux et une adaptation aux nouvelles habitudes de consommation."
          bulletPoints={[
            <p>
              <span className="font-bold">Diversitfé</span> des modes de
              paiement.
            </p>,
            <p>
              Paiements <span className="font-bold">sécurisés et rapides</span>.
            </p>,
            <p>
              Terminaux robustes{" "}
              <span className="font-bold">à longue autonomie</span>.
            </p>,
          ]}
          imgName="section4-home.png"
          altImg="payment machine"
          align="right"
          goTo="/tpe"
          from="/#tpe"
        />

        <Section
          id="sectionReputation"
          title="Service e-réputation"
          subTitle="MAÎTRISEZ VOTRE RÉPUTATION EN LIGNE grâce à B Partner"
          text="Évaluez et améliorez votre image en ligne, pour renforcer votre réputation auprès de vos clients et partenaires :"
          bulletPoints={[
            <p>
              <span className="font-bold">Bilan offert :</span> Recevez une
              évaluation initiale de votre réputation en ligne.
            </p>,
            <p>
              <span className="font-bold">Analyse détaillée :</span> Comprenez
              les éléments clés qui influencent votre image.
            </p>,
            <p>
              Service disponible uniquement sur les offres{" "}
              <span className="font-bold">Prestige</span> et{" "}
              <span className="font-bold">Prestige Business</span>.
            </p>,
          ]}
          imgName="section5-home.png"
          altImg="woman checking her account"
          align="left"
          goTo="/eReputation"
          from="/#sectionReputation"
        />

        <SectionOffers />
        <div
          id="valeurs"
          className={`${
            isMobile ? "" : "container-snap"
          } flex flex-col font-poppins px-[1.5rem] lg:justify-center lg:pt-[4rem]`}
        >
          <p className="uppercase font-bold text-[1.5rem] lg:text-[2.5rem]">
            Nos Valeurs
          </p>
          <p className="font-bold text-[1.25rem] mt-[1.5rem] lg:text-[1.875rem]">
            "Chez B Partner,{" "}
            <span className="text-main-red">
              vos fonds ne sont jamais réinvestis
            </span>
            .
          </p>
          <p className="font-semibold lg:text-[1.25rem]">
            Ils restent donc disponibles à tout moment pour nos clients.”
          </p>
          <p className="mt-[1.5rem] text-dark-grey">
            L’excellence et la qualité sont nos priorités absolues pour vous
            accompagner dans la gestion de vos finances.
          </p>
          <p className="text-dark-grey">
            Nos <span className="font-bold">certifications ISO</span> renforcent
            notre expertise et soutiennent vos projets tout en assurant la
            sécurité de vos données.
          </p>
          <div className="xx:w-fit xx:mx-auto mb-8">
            <Carrousel>
              <Card
                img="./assets/images/imgCar1.png"
                alt={"woman on laptop"}
                title={<p className="font-bold text-[1rem]">Ethique</p>}
                text={
                  "Votre argent n'est pas réinvestis et il reste à tout moment disponible pour vos dépenses, achats et investissements."
                }
                goTo={"/ethique"}
                from="/#valeurs"
              />
              <Card
                img="./assets/images/imgCar2.png"
                alt={"carte de crédit b partner"}
                title={<p className="font-bold text-[1rem]">Proximité</p>}
                text={"Un conseiller est à votre écoute en permanence."}
                goTo={"/proximite"}
                from="/#valeurs"
              />
              <Card
                img="./assets/images/imgCar3.png"
                alt={"people talking"}
                title={<p className="font-bold text-[1rem]">Tranquilité</p>}
                text={
                  "Simplifiez votre vie et profitez d'une gestion sereine de vos finances."
                }
                goTo={"/tranquilite"}
                from="/#valeurs"
              />
              <Card
                img="./assets/images/imgCar4.png"
                alt={"man on laptop"}
                title={<p className="font-bold text-[1rem]">Exclusivité</p>}
                text={"Nous vous proposons des services uniques et exclusifs"}
                goTo={"/exclusivite"}
                from="/#valeurs"
              />
            </Carrousel>
          </div>
        </div>
        <div
          className={`${
            isMobile ? "" : "container-snap"
          } hidden flex flex-col font-poppins px-[1.5rem] lg:justify-center lg:pt-[2.5rem] mt-[2.5rem] mb-[2.5rem]`}
        >
          <p className="w-full text-main-red uppercase font-bold tracking-[0.15rem] text-center font-poppins leading-relaxed lg:mt-[2rem]">
            Pour rester informé de toute l’actualité B PArtner
          </p>
          <p className="text-[1.5rem] font-bold leading-relaxed mt-[1.5rem] lg:text-[2.5rem]">
            Inscrivez-vous à notre newsletter
          </p>
          <p className="w-full mt-[1rem] lg:w-[48rem] lg:mx-auto">
            Soyez les premiers à découvrir nos offres exclusives, les dernières
            actualités, des invitations à des événements spéciaux, et des
            opportunités réservées à notre clientèle.
          </p>
          <div className="relative mt-[2.12rem] lg:mt-[1rem] w-fit lg:mx-auto">
            <input
              type="text"
              className="h-[3.75rem] w-full border-b font-poppins pl-4 lg:w-[27.81rem]"
              placeholder="Adresse mail"
            />
            <img
              src="./assets/svg/arrow-right.svg"
              alt="right arrow"
              className="border p-2 rounded-full absolute right-4 top-3 cursor-pointer"
            />
          </div>
          <div className="mt-8 lg:mt-4">
            <p className="text-center text-[1.5rem] font-bold lg:text-[2.5rem]">
              Télécharger l'application
            </p>
            <p className="text-center mt-[1rem]">
              Simplifiez votre vie et accédez rapidement à vos comptes.
            </p>
            <div className="border border-main-red w-[11.6rem] h-[11.6rem] mx-auto flex justify-center items-center rounded-xl mt-[2rem]">
              <img
                alt="qr code"
                src="./assets/images/qr-code.png"
                className="w-[7.31rem] h-[7.31rem]"
              />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
