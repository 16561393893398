// functions.js

/**
 * Cette fonction détermine la meilleure offre parmi plusieurs options.
 * Elle identifie l'offre avec la plus grande valeur absolue de pourcentage d'économie.
 * En cas d'égalité des pourcentages, elle sélectionne l'offre avec le coût annuel le plus bas.
 * Si userInfo.has_printing est vrai, l'offre avec has_printing égal à true sera priorisée.
 * 
 * @param {Object} apiResult - Le résultat de l'API contenant les données des offres.
 * @param {Object} userInfo - Informations sur l'utilisateur, incluant l'option has_printing.
 * @returns {string} - La clé de l'offre avec la meilleure économie.
 */
export const getBestOffer = (apiResult, userInfo) => {
  const { Economie, ...offers } = apiResult;

  let bestOfferKey = null;
  let maxPercentage = -Infinity;
  let lowestCostWithAcquisition = Infinity;
  let priorityPrintingOfferFound = false;

  for (const [key, percentage] of Object.entries(Economie)) {
    const absPercentage = Math.abs(parseFloat(percentage));
    const currentCostWithAcquisition = offers[key]['Coût annuel Estimé avec acquisition'];
    const hasPrinting = apiResult.details[key]?.has_printing === true;

    // Priorise les offres avec has_printing si userInfo.has_printing est true
    if (userInfo.has_printing) {
      if (hasPrinting && !priorityPrintingOfferFound) {
        maxPercentage = absPercentage;
        bestOfferKey = key;
        lowestCostWithAcquisition = currentCostWithAcquisition;
        priorityPrintingOfferFound = true;
      } else if (hasPrinting && absPercentage > maxPercentage) {
        maxPercentage = absPercentage;
        bestOfferKey = key;
        lowestCostWithAcquisition = currentCostWithAcquisition;
      } else if (hasPrinting && absPercentage === maxPercentage && currentCostWithAcquisition < lowestCostWithAcquisition) {
        bestOfferKey = key;
        lowestCostWithAcquisition = currentCostWithAcquisition;
      }
    } else {
      // Logique originale si has_printing n'est pas prioritaire
      if (absPercentage > maxPercentage) {
        maxPercentage = absPercentage;
        bestOfferKey = key;
        lowestCostWithAcquisition = currentCostWithAcquisition;
      } else if (absPercentage === maxPercentage && currentCostWithAcquisition < lowestCostWithAcquisition) {
        bestOfferKey = key;
        lowestCostWithAcquisition = currentCostWithAcquisition;
      }
    }
  }

  return bestOfferKey;
};

/**
* Retourne la seconde meilleure offre, c'est-à-dire celle qui n'est pas la meilleure.
* 
* @param {Object} apiResult - Le résultat de l'API contenant les offres.
* @param {Object} userInfo - Informations sur l'utilisateur, incluant l'option has_printing.
* @returns {string} - La clé de l'offre autre que la meilleure.
*/
export const secondOffer = (apiResult, userInfo) => {
  const bestOfferKey = getBestOffer(apiResult, userInfo);
  
  // Trouve la première clé différente de bestOfferKey
  for (const key in apiResult.Economie) {
    if (key !== bestOfferKey) {
      return key;
    }
  }

  return null; // Si aucune autre offre n'est trouvée
};
