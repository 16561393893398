import React, { useState } from 'react';
import useIsMobile from '../hooks/useIsMobile';
import CardFormule from './CardFormule';
import Tableau from './Tableau';

export default function Comparatif() {
  const isMobile = useIsMobile();
  const [formule, setFormule] = useState('premium');
  const [activeSection, setActiveSection] = useState(null); // État pour suivre la section active

  const changeFormule = (formule) => {
    setFormule(formule);
  };

  const toggleSection = (index) => {
    // Si l'index est déjà actif, on le désactive, sinon on active la nouvelle section
    setActiveSection(activeSection === index ? null : index);
  };

  const card = {
    prestige: {
      title: 'Prestige',
      monthPrice: 46,
      yearPrice: 39,
      img: './assets/images/prestige-card.png',
    },
    premium: {
      title: 'Premium',
      monthPrice: 22,
      yearPrice: 19,
      img: './assets/images/premium-card.png',
    },
  };

  const comparatif = [
    {
      sectionName: 'Comptes et opérations',
      data: [
        { title: 'Compte en Euro', premium: 'valid', prestige: 'valid' },
        { title: 'Paiement sortant en euros (SEPA)', premium: 'valid', prestige: 'valid' },
        { title: 'Paiement instantanné en euros (SEPA)', premium: 'valid', prestige: 'valid' },
        { title: 'Paiement programmés et récurrents', premium: 'valid', prestige: 'valid' },
        { title: 'Paiement en devise', premium: 'Plus de 50 devises', prestige: 'Plus de 50 devises' },
      ],
    },
    {
      sectionName: 'Services à valeur ajoutée',
      data: [
        { title: 'Programme fidélité B Partner', premium: 'valid', prestige: 'valid' },
        { title: 'Conciergerie', premium: 'valid', prestige: 'valid' },
        { title: 'Dépannage cash à l’étranger', premium: '2%', prestige: '2%' },
      ],
    },
    {
      sectionName: 'Services Prestige',
      data: [
        { title: 'Assistant personnel', premium: 'Non', prestige: 'valid' },
        { title: 'Conseiller financier personnel', premium: 'Non', prestige: 'valid' },
        { title: 'Bilan E-reputation annuelle', premium: 'Non', prestige: 'valid' },
        { title: 'Accès B Partner Club', premium: 'Non', prestige: 'valid' },
      ],
    },
    {
      sectionName: 'Paiement au quotidien',
      data: [
        { title: 'Paiements en zone euro gratuits', premium: 'valid', prestige: 'valid' },
        { title: 'Carte de débit VISA internationale', premium: 'PVC (recyclable)', prestige: 'Métal' },
        { title: 'Carte virtuelle gratuite', premium: '5 cartes offertes', prestige: 'Illimité' },
        { title: 'Plafonds de paiement', premium: 'Gestion en ligne', prestige: 'Gestion en ligne' },
        { title: 'Paiements en euro hors zone sepa', premium: 'valid', prestige: 'valid' },
      ],
    },
    {
      sectionName: 'Retraits et Services',
      data: [
        { title: 'Cash-back pour tous les paiements', premium: "Jusqu'à 0,75%", prestige: "Jusqu'à 1,25%" },
        { title: 'Retraits en zone France', premium: '3 gratuits', prestige: '5 gratuits' },
      ],
    },
  ];

  return (
    <div id='comparatifPart' className={`${isMobile ? '' : 'container-snap'} flex flex-col font-poppins px-[1.5rem] lg:pt-[6.5rem] overflow-hidden lg:justify-center xx:pt-[10rem] xx:px-[3rem]`}>
      <div className="flex flex-col lg:flex-row lg:h-[16rem] lg:items-center " id="comparatif-header">
        <div className='lg:w-[25%]'>
          <p className="uppercase text-main-red font-bold tracking-widest lg:text-left lg:w-fit">NOS OFFRES POUR LES PARTICULIER</p>
          <p className="uppercase font-bold text-[1.5rem] mt-2 lg:text-[2.6rem] lg:text-left lg:w-fit">DÉCOUVREZ NOS OFFRES</p>
          <div className={`${!isMobile && 'hidden'} flex flex-row w-full justify-around text-[1.125rem] mt-4`}>
            <p
              onClick={() => changeFormule('premium')}
              className={`${formule === 'premium' && 'text-main-red font-semibold border border-main-red rounded'} p-[8px]`}
            >
              Premium
            </p>
            <p
              onClick={() => changeFormule('prestige')}
              className={`${formule === 'prestige' && 'text-main-red font-semibold border border-main-red rounded'} p-[8px]`}
            >
              Prestige
            </p>
          </div>
        </div>
        {isMobile ? <CardFormule data={card[formule]} /> : 
        <div className='flex flex-row lg:ml-auto lg:justify-end lg:space-x-14 lg:pr-8 xx:space-x-[15rem] xx:pr-[5rem]'>
        <CardFormule from='/particuliers#comparatifPart' data={card['premium']} />
        <CardFormule from='/particuliers#comparatifPart' data={card['prestige']} />
        </div>
        }

      </div>
      <div className='lg:h-full flex flex-col justify-center'>
        {comparatif.map((section, index) => (
          <Tableau
            key={index}
            sectionName={section.sectionName}
            data={section.data}
            formule={formule}
            isActive={activeSection === index} // Vérifie si la section est active
            toggleSection={() => toggleSection(index)} // Fonction pour changer la section active
          />
        ))}
      </div>
    </div>
  );
}
