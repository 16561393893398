import React from "react";
import useIsMobile from "../hooks/useIsMobile";
import SectionParticulier from "../components/SectionParticulier";
import { Link } from "react-router-dom";
import Carrousel from "../components/Carroussel";
import CardParticulier from "../components/CardParticulier";
import ComparatifPro from "../components/ComparatifPro";
import Section from "../components/Section";
import Footer from "../components/Footer";
import CustomLink from "../components/CustomLink";
export default function Professionnel() {
  const isMobile = useIsMobile();
  return (
    <div
      className={`flex flex-col font-poppins ${
        !isMobile ? "h-auto overflow-hidden" : ""
      }`}
    >
      <div className={`${!isMobile ? "scroll-container" : ""}`}>
        <div className="container-snap relative h-screen bg-black">
          <img
            className="absolute bottom-0  lg:h-[60%] object-cover z-0 lg:right-0 xx:h-[70%]"
            src="./assets/images/cardHome.png"
          />

          <div className="relative z-10 flex px-[1.5rem] lg:px-[3rem] xx:px-[6rem] flex-col pt-[22%] lg:pt-[8%] xx:pt-[5%] text-white h-screen justify-end lg:justify-start">
            <p className="text-[1.75rem] font-semibold leading-[2.45rem] text-left lg:text-[2rem] lg:w-[32.0625rem] lg:mt-[5rem] xx:text-[2.75rem] xx:w-[45.0625rem] xx:leading-[3.5rem]">
              Accélérez votre{" "}
              <span className="text-main-red">développement international</span>{" "}
              en bénéficiant d'une gestion financière fluide{" "}
            </p>
            <p className="font-dmSans leading-[1.6875rem] font-medium text-left lg:mt-[1.25rem] lg:w-[32.0625rem] xx:w[45.0625rem] xx:text-[1.125rem]">
              Transactions simplifiées, comptes en multidevises et optimisation
              de vos coûts.
            </p>
            <CustomLink
              from="/professionnels"
              className="relative w-fit h-fit mt-[2rem] mb-[2rem] lg:w-[14.5rem]"
              to="/professionnels"
              scrollTo={"comparatifPro"}
            >
              <button className="w-[13.875rem] h-[3rem] bg-white text-black font-dmSans font-bold rounded">
                Découvrir nos offres
              </button>
              <img
                alt="chevron right"
                src="./assets/svg/chevron-right-red.svg"
                className="absolute right-3 top-[15.5px] lg:right-5 lg:top-[16px]"
              />
            </CustomLink>
          </div>
        </div>
        <SectionParticulier
          id="bpClubSection"
          title={
            <>
              notre offre <span className="text-main-red">prestige</span>
            </>
          }
          lign="DES SERVICES QUi accompagnent Votre entreprise vers L’EXCELLENCE"
          subTitle="Économisez du temps et gagnez en tranquillité avec nos services Prestige
"
          text="Chez B Partner, nous sommes convaincus de la réussite de votre entreprise. C’est pourquoi nous vous offrons des solutions personnalisées, conçues pour soutenir votre croissance et vous apporter la sérénité nécessaire pour vous concentrer sur votre cœur de métier. 
                    B Partner vous propose des solutions financières efficaces, conçues pour optimiser votre gestion financière et libérer votre potentiel de croissance."
          imgName="img-pro-1.png"
          textBtn="Souscrire"
          altImg="woman checking her account"
          align="left"
          goTo="https://app.b-partner.com/register"
          blank={true}
        />
        <div
          className={`${
            isMobile ? "" : "container-snap"
          } flex flex-col font-poppins px-[1.5rem] lg:justify-center lg:pt-[4rem] mb-12`}
        >
          <p className="uppercase text-main-red tracking-wider font-semibold mt-2 lg:tracking-widest xx:text-[1.25rem]">
            DES SERVICES D’EXCEPTION pour votre entreprise
          </p>
          <p className="font-bold text-[1.25rem] mt-[1.5rem] lg:text-[1.875rem] uppercase">
            Inclus dans l'offre prestige
          </p>
          <div className="xx:w-full xx:mx-auto">
            <Carrousel id="valeurs">
              <CardParticulier
                img="./assets/images/tpe1.png"
                alt={"reception"}
                title={
                  <p className="font-bold text-[1rem] text-left">
                    Terminaux de paiement
                  </p>
                }
                text={
                  "Acceptez les paiements de vos clients en optant pour notre offre de paiement sur votre téléphone Android ou pour l’un de nos terminaux de paiement (TPE) robuste et à large autonomie."
                }
                goTo={"/tpe"}
                from="/professionnels#valeurs"
              />
              <CardParticulier
                img="./assets/images/card2-car2.png"
                alt={"party"}
                title={<p className="font-bold text-[1rem]">B Partner Club</p>}
                text={
                  "L’offre Prestige vous donne accès à un Club d’Affaires privé afin de développer votre réseau et votre Business. B Partner Club, c’est aussi des entrées et invitations à des invitations à des lieux et évènements exclusifs sélectionnés pour nos clients."
                }
                goTo={"/bPartnerClub"}
                from="/professionnels#valeurs"
              />
              <CardParticulier
                img="./assets/images/card3-car2.png"
                alt={"woman"}
                title={
                  <p className="font-bold text-[1rem]">B Partner Fidélité</p>
                }
                text={
                  "Ce programme de Fidélité est conçu pour remercier nos clients les plus fidèles. Cumulez des points de Fidélités et choisissez la manière dont vous souhaitez les dépenser par la suite."
                }
                goTo={"/bPartnerFidelite"}
                from="/professionnels#valeurs"
              />
              <CardParticulier
                img="./assets/images/section3-home.png"
                alt={"man on laptop"}
                title={
                  <p className="font-bold text-[1rem]">Paiements en devise</p>
                }
                text={
                  "Réalisez vos paiements en devise directement sur votre Application B Partner, et payer dans plus de 50 devises et vers plus d’une centaine de pays à frais réduits. Suivez vos paiements en temps-réel sur votre téléphone ou votre ordinateur. "
                }
                goTo={"/devises"}
                from="/professionnels#valeurs"
              />
              <CardParticulier
                img="./assets/images/card1-car2.png"
                alt={"man on laptop"}
                title={
                  <p className="font-bold text-[1rem]">Assistant personnel</p>
                }
                text={
                  "Un service luxueux afin de répondre à vos demandes de réservations de vol, d‘hôtel, chauffeur personnel ou toute autre requête. L’assistant personnel est joignable 24h/24 tous les jours de la semaine pour vous faciliter la vie."
                }
                goTo={"/assistant"}
                from="/professionnels#valeurs"
              />
              <CardParticulier
                img="./assets/images/section5-home.png"
                alt={"man on laptop"}
                title={
                  <p className="font-bold text-[1rem]">Service e-reputation</p>
                }
                text={
                  "Un service exclusif pour protéger votre image et votre réputation sur internet et sur les réseaux sociaux. Un bilan de votre réputation vous est offert une fois votre compte activé "
                }
                goTo={"/eReputation"}
                from="/professionnels#valeurs"
              />
            </Carrousel>
          </div>
        </div>
        <SectionParticulier
          id="bpClubSection"
          title={
            <>
              notre offre <span className="text-main-red">premium</span>
            </>
          }
          lign="l’EFFICACITÉ CHAQUE JOUR au service de votre entreprise"
          subTitle="VOTRE GESTION DE COMPTE SIMPLIFIÉE, POUR VOUS CONCENTRER SUR L’ESSENTIEL"
          text="Simplifiez vos opérations financières avec B Partner Premium. Bénéficiez d'une solution de paiement complète, incluant des paiements SEPA, instantanés et internationaux. Nos services sont conçus pour vous faire gagner du temps et vous offrir une visibilité totale sur vos comptes.Nos services personnalisés sont pensés pour répondre à vos besoins spécifiques et vous offrir une expérience unique."
          imgName="pro-2.png"
          textBtn="Souscrire"
          altImg="woman checking her account"
          align="right"
          goTo="https://app.b-partner.com/register"
          blank={true}
        />
        <div
          className={`${
            isMobile ? "" : "container-snap"
          } flex flex-col font-poppins px-[1.5rem] lg:justify-center lg:pt-[4rem] mb-12`}
        >
          <p className="uppercase text-main-red tracking-wider font-semibold mt-2 lg:tracking-widest xx:text-[1.25rem]">
            DES SERVICES ADAPTÉS
          </p>
          <p className="font-bold text-[1.25rem] mt-[1.5rem] lg:text-[1.875rem] uppercase">
            Inclus dans l'offre premium
          </p>
          <div className="xx:w-fit xx:mx-auto">
            <Carrousel id="valeurs2">
              <CardParticulier
                img="./assets/images/tpe1.png"
                alt={"reception"}
                title={
                  <p className="font-bold text-[1rem]">Terminaux de paiement</p>
                }
                text={
                  "Acceptez les paiements de vos clients en optant pour notre offre de paiement sur votre téléphone Android ou pour l’un de nos terminaux de paiement (TPE) robuste et à large autonomie."
                }
                goTo={"/tpe"}
                from="/professionnels#valeurs2"
              />
              <CardParticulier
                img="./assets/images/card3-car2.png"
                alt={"woman"}
                title={
                  <p className="font-bold text-[1rem]">B Partner Fidélité</p>
                }
                text={
                  "Ce programme de Fidélité est conçu pour remercier nos clients les plus fidèles. Cumulez des points de Fidélités et choisissez la manière dont vous souhaitez les dépenser par la suite."
                }
                goTo={"/bPartnerFidelite"}
                from="/professionnels#valeurs2"
              />
              <CardParticulier
                img="./assets/images/section3-home.png"
                alt={"man on laptop"}
                title={
                  <p className="font-bold text-[1rem]">Paiements en devise</p>
                }
                text={
                  "Réalisez vos paiements en devise directement sur votre Application B Partner, et payer dans plus de 50 devises et vers plus d’une centaine de pays à frais réduits. Suivez vos paiements en temps-réel sur votre téléphone ou votre ordinateur. "
                }
                goTo={"/devises"}
                from="/professionnels#valeurs2"
              />
              <CardParticulier
                img="./assets/images/section2-home.png"
                alt={"man on laptop"}
                title={
                  <p className="font-bold text-[1rem]">Services conciergerie</p>
                }
                text={
                  "Nos concierges B Partner seront à vos écoute afin de répondre à vos sollicitions. Pour l’organisation de vos prochaines vacances ou pour réserver une table dans un restaurant prisé, faites appel à votre Conciergerie B Partner"
                }
                goTo={"/conciergerie"}
                from="/professionnels#valeurs2"
              />
              <CardParticulier
                img="./assets/images/card5-car2.png"
                alt={"party"}
                title={
                  <p className="font-bold text-[1rem]">Conseiller financier</p>
                }
                text={
                  "Nos experts financiers et patrimoniaux sont à votre écoute pour établir un bilan personnalisé de vos projets. Que ce soit en immobilier, en épargne financière ou dans d’autres types de placements, nos conseillers sont là pour vous accompagner."
                }
                goTo={"/conseiller"}
                from="/professionnels#valeurs2"
              />
            </Carrousel>
          </div>
        </div>
        <ComparatifPro />
        <Section
          id="sectionReputation"
          title="B Partner club"
          subTitle="DÉPENSEZ TOUT EN ÉTANT RÉCOMPENSÉ"
          text="Le B Partner Club vous permet d’obtenir de nombreux privilèges comme des réductions chez nos partenaires ou des avantages B Partner exclusifs."
          bulletPoints={[
            <p>
              Profitez de <span className="font-bold">réductions</span> chez nos
              partenaires
            </p>,
            <p>
              Augmentez de statut et{" "}
              <span className="font-bold">multipliez vos points</span>{" "}
            </p>,
            <p>
              Cumulez du <span className="font-bold">cashback</span> en
              utilisant votre carte{" "}
            </p>,
          ]}
          imgName="section1-home.png"
          altImg="woman checking her account"
          align="right"
          goTo="/bPartnerClub"
          from="/professionnels#sectionReputation"
        />
        <Footer />
      </div>
    </div>
  );
}
