import React from 'react';
import { useAppContext } from '../contexts/AppContext';

export default function Modal() {
    const { state, updateContext } = useAppContext();
    
    if (state.modalOpen) {
        return (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                <div className="bg-white rounded shadow-lg p-8 relative w-full h-full lg:w-[50rem] lg:h-4/5 overflow-y-scroll">
                    <img alt='close' src='./assets/svg/cancel.svg' className='w-[1.5rem] h-[1.5rem] absolute right-6 top-6  cursor-pointer' onClick={() => updateContext('modalOpen', false)}/>
                    {state.modalElement}
                </div>
            </div>
        );
    } else {
        return null;
    }
}
