import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

const DEVISES = [
  {
    code: "AZN",
    nom: "Manat azerbaïdjanais",
    pro: false,
    flagUrl: "https://flagcdn.com/az.svg",
  },
  {
    code: "BGN",
    nom: "Lev bulgare",
    pro: false,
    flagUrl: "https://flagcdn.com/bg.svg",
  },
  {
    code: "BHD",
    nom: "Dinar bahreïnien",
    pro: false,
    flagUrl: "https://flagcdn.com/bh.svg",
  },
  {
    code: "BND",
    nom: "Dollar de Brunei",
    pro: false,
    flagUrl: "https://flagcdn.com/bn.svg",
  },
  {
    code: "CNY",
    nom: "Yuan chinois Renminbi",
    pro: true,
    flagUrl: "https://flagcdn.com/cn.svg",
  },
  {
    code: "EGP",
    nom: "Livre égyptienne",
    pro: false,
    flagUrl: "https://flagcdn.com/eg.svg",
  },
  {
    code: "KRW",
    nom: "Won sud-coréen",
    pro: false,
    flagUrl: "https://flagcdn.com/kr.svg",
  },
  {
    code: "KWD",
    nom: "Dinar koweïtien",
    pro: false,
    flagUrl: "https://flagcdn.com/kw.svg",
  },
  {
    code: "LKR",
    nom: "Roupie sri-lankaise",
    pro: false,
    flagUrl: "https://flagcdn.com/lk.svg",
  },
  {
    code: "MAD",
    nom: "Dirham marocain",
    pro: false,
    flagUrl: "https://flagcdn.com/ma.svg",
  },
  {
    code: "MGA",
    nom: "Ariary malgache",
    pro: false,
    flagUrl: "https://flagcdn.com/mg.svg",
  },
  {
    code: "OMR",
    nom: "Rial omanais",
    pro: false,
    flagUrl: "https://flagcdn.com/om.svg",
  },
  {
    code: "PEN",
    nom: "Nuevo Sol péruvien",
    pro: false,
    flagUrl: "https://flagcdn.com/pe.svg",
  },
  {
    code: "PGK",
    nom: "Papouasie-Nouvelle-Guinée Kina",
    pro: false,
    flagUrl: "https://flagcdn.com/pg.svg",
  },
  {
    code: "PKR",
    nom: "Roupie du Pakistan",
    pro: false,
    flagUrl: "https://flagcdn.com/pk.svg",
  },
  {
    code: "SAR",
    nom: "Riyal saoudien",
    pro: false,
    flagUrl: "https://flagcdn.com/sa.svg",
  },
  {
    code: "SCR",
    nom: "Roupie seychelloise",
    pro: false,
    flagUrl: "https://flagcdn.com/sc.svg",
  },
  {
    code: "TWD",
    nom: "Dollar taïwanais",
    pro: false,
    flagUrl: "https://flagcdn.com/tw.svg",
  },
  {
    code: "TZS",
    nom: "Shilling tanzanien",
    pro: false,
    flagUrl: "https://flagcdn.com/tz.svg",
  },
  {
    code: "VND",
    nom: "Dong vietnamien",
    pro: false,
    flagUrl: "https://flagcdn.com/vn.svg",
  },
  {
    code: "XOF",
    nom: "Franc CFA d'Afrique de l'Ouest",
    pro: false,
    flagUrl: "https://flagcdn.com/sn.svg",
  },
  {
    code: "AED",
    nom: "Dirham des Émirats arabes unis",
    pro: false,
    flagUrl: "https://flagcdn.com/ae.svg",
  },
  {
    code: "AUD",
    nom: "Dollar australien",
    pro: false,
    flagUrl: "https://flagcdn.com/au.svg",
  },
  {
    code: "CAD",
    nom: "Dollar canadien",
    pro: false,
    flagUrl: "https://flagcdn.com/ca.svg",
  },
  {
    code: "CHF",
    nom: "Franc suisse",
    pro: false,
    flagUrl: "https://flagcdn.com/ch.svg",
  },
  {
    code: "CNH",
    nom: "Renminbi chinois offshore",
    pro: true,
    flagUrl: "https://flagcdn.com/cn.svg",
  },
  {
    code: "CZK",
    nom: "Krouna tchèque",
    pro: false,
    flagUrl: "https://flagcdn.com/cz.svg",
  },
  {
    code: "DKK",
    nom: "Couronnes danoises",
    pro: false,
    flagUrl: "https://flagcdn.com/dk.svg",
  },
  {
    code: "EUR",
    nom: "L'euro",
    pro: false,
    flagUrl: "https://flagcdn.com/eu.svg",
  },
  {
    code: "FJD",
    nom: "Dollar fidjien",
    pro: false,
    flagUrl: "https://flagcdn.com/fj.svg",
  },
  {
    code: "GBP",
    nom: "Livre sterling",
    pro: false,
    flagUrl: "https://flagcdn.com/gb.svg",
  },
  {
    code: "HKD",
    nom: "Dollar de Hong Kong",
    pro: false,
    flagUrl: "https://flagcdn.com/hk.svg",
  },
  {
    code: "HUF",
    nom: "Forint hongrois",
    pro: false,
    flagUrl: "https://flagcdn.com/hu.svg",
  },
  {
    code: "IDR",
    nom: "Rupiah indonésienne",
    pro: false,
    flagUrl: "https://flagcdn.com/id.svg",
  },
  {
    code: "ILS",
    nom: "Shekel israélien",
    pro: false,
    flagUrl: "https://flagcdn.com/il.svg",
  },
  {
    code: "INR",
    nom: "Roupie indienne",
    pro: false,
    flagUrl: "https://flagcdn.com/in.svg",
  },
  {
    code: "JPY",
    nom: "Yen japonais",
    pro: false,
    flagUrl: "https://flagcdn.com/jp.svg",
  },
  {
    code: "MXN",
    nom: "Peso mexicain",
    pro: false,
    flagUrl: "https://flagcdn.com/mx.svg",
  },
  {
    code: "MYR",
    nom: "Ringgit malaisien",
    pro: false,
    flagUrl: "https://flagcdn.com/my.svg",
  },
  {
    code: "NOK",
    nom: "Couronnes norvégiennes",
    pro: false,
    flagUrl: "https://flagcdn.com/no.svg",
  },
  {
    code: "NZD",
    nom: "Dollar néo-zélandais",
    pro: false,
    flagUrl: "https://flagcdn.com/nz.svg",
  },
  {
    code: "PHP",
    nom: "Peso philippin",
    pro: false,
    flagUrl: "https://flagcdn.com/ph.svg",
  },
  {
    code: "PLN",
    nom: "Zloty polonais",
    pro: false,
    flagUrl: "https://flagcdn.com/pl.svg",
  },
  {
    code: "SBD",
    nom: "Dollar des Îles Salomon",
    pro: false,
    flagUrl: "https://flagcdn.com/sb.svg",
  },
  {
    code: "SEK",
    nom: "Couronnes suédoises",
    pro: false,
    flagUrl: "https://flagcdn.com/se.svg",
  },
  {
    code: "SGD",
    nom: "Dollar de Singapour",
    pro: false,
    flagUrl: "https://flagcdn.com/sg.svg",
  },
  {
    code: "THB",
    nom: "Baht thaïlandais",
    pro: false,
    flagUrl: "https://flagcdn.com/th.svg",
  },
  {
    code: "TOP",
    nom: "Tonga Pa'anga",
    pro: false,
    flagUrl: "https://flagcdn.com/to.svg",
  },
  {
    code: "TRY",
    nom: "Livre turque",
    pro: false,
    flagUrl: "https://flagcdn.com/tr.svg",
  },
  {
    code: "USD",
    nom: "Dollar américain",
    pro: false,
    flagUrl: "https://flagcdn.com/us.svg",
  },
  {
    code: "VUV",
    nom: "Vanuatu Vatu",
    pro: false,
    flagUrl: "https://flagcdn.com/vu.svg",
  },
  {
    code: "WST",
    nom: "Tala samoan",
    pro: false,
    flagUrl: "https://flagcdn.com/ws.svg",
  },
  {
    code: "XPF",
    nom: "CFP Franc",
    pro: false,
    flagUrl: "https://flagcdn.com/nc.svg",
  },
  {
    code: "ZAR",
    nom: "Rand sud-africain",
    pro: false,
    flagUrl: "https://flagcdn.com/za.svg",
  },
];

const SelectDevises = () => {
  const sortedDevises = DEVISES.sort((a, b) => a.code.localeCompare(b.code));

  return (
    <div className="space-y-1 lg:w-[24rem] xx:w-[35rem] mt-3">
      <Select
        placeholder="GO"
        className="bg-white"
        render
        sx={{
          "& .MuiInputBase-input": {
            paddingTop: "16px",
            paddingBottom: "16px",
          },

          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "black",
          },

          "&.Mui-error .MuiOutlinedInput-notchedOutline": {
            borderColor: "#FF0000",
          },
        }}
        fullWidth
        MenuProps={{
          PaperProps: {
            sx: {
              maxHeight: 300,
            },
          },
        }}
      >
        {sortedDevises.map((option) => (
          <MenuItem key={option.code} value={option.code}>
            <div className="flex items-center space-x-2">
              <img
                src={option.flagUrl}
                alt={option.code}
                className="w-6 h-6 object-contain "
              />
              <span>
                <span className="font-bold">{option.code}</span> - {option.nom}
              </span>
              {option.pro && (
                <span style={{ color: "red", marginLeft: "10px" }}>
                  Pro uniquement
                </span>
              )}
            </div>
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default SelectDevises;
